import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';

const Terms = () => {
    const navigate = useNavigate();
    return (
        <div className='pt-24 pb-12 max-w-[1024px] m-auto max-[1024px]:px-5'>
            <p className="my-4 text-3xl text-gray-700">Terms and Agreement</p>
            <p className="mb-3 text-gray-700 text-justify">
                Welcome to Subliva, a platform designed to simplify the subleasing process by connecting individuals seeking to sublease their properties with prospective renters. By listing a property on Subliva, you agree to the following terms and conditions.
            </p>
            <p className="mb-3 text-gray-700 text-justify">
                These terms govern your use of the platform and establish a clear understanding of Subliva's role as an intermediary. Subliva is not liable for disputes, agreements, or issues that arise between users of the platform, though we provide features to facilitate smoother transactions.
                Subliva provides a platform for listing and browsing properties and implements a verification process for listings.
            </p>
            <p className="mb-3  text-gray-700 text-justify">
                This process includes requiring users to submit supporting documents, such as a lease agreement or utility bill, to confirm the authenticity of the listing. While Subliva strives to ensure that all verified listings are legitimate, we do not guarantee compliance with lease terms, landlord permissions, or local regulations. You are solely responsible for ensuring that your listing complies with all applicable laws and lease agreements. Subliva is not responsible for legal disputes or claims arising from non-compliance.
            </p>
            <p className="mb-3  text-gray-700 text-justify">
                Additionally, Subliva facilitates the sublease process by generating an automated contract that includes payment terms, conditions, and balances between the lister and the renter. When a sublease agreement is signed through the platform, Subliva will automatically provide both parties with a record of the balance owed by one party to the other. This feature is intended to clarify payment arrangements and reduce misunderstandings. However, Subliva does not enforce payment obligations, mediate disputes, or assume liability for non-payment, contract breaches, or other issues related to the sublease agreement. 
            </p>

            <p className="mb-3  text-gray-700 text-justify">
                Users are responsible for fulfilling the terms of their agreements and resolving disputes directly with one another.
                By using Subliva, you acknowledge and agree that we do not participate in the sublease agreement itself. Subliva does not verify the identity or reliability of users beyond the document verification process, nor do we guarantee the quality, safety, or condition of listed properties. All interactions and transactions between users are conducted at their own risk. Subliva is not liable for claims, damages, or disputes arising from issues such as misrepresentation, property damage, non-payment, or personal injury occurring at the listed property.
                When creating a listing, you agree that all information provided is accurate, truthful, and complete. Subliva reserves the right to remove or modify listings that are deemed false, misleading, or inappropriate. 
            </p>

            <p className="mb-3  text-gray-700 text-justify">
                You are prohibited from listing properties you do not have the legal right to sublease, providing false or misleading information, or using the platform for unauthorized or illegal purposes. Subliva reserves the right to suspend or terminate your account and remove your listings if you violate these terms. Suspension or termination does not relieve you of obligations under any existing sublease agreements.
                Subliva may offer optional paid features, such as sponsored listings or premium memberships, to enhance your experience on the platform.
            </p>

            <p className="mb-3  text-gray-700 text-justify">
                However, Subliva does not charge fees for creating standard listings. Any payment disputes or issues related to sublease arrangements must be resolved between the lister and the renter, as Subliva does not intervene in financial disputes or contractual disagreements.
                To the fullest extent permitted by law, you agree to indemnify and hold Subliva harmless from any claims, damages, or liabilities arising out of your use of the platform. This includes, but is not limited to, disputes with renters, violations of lease agreements or laws, and any incidents occurring at the listed property. 
                Subliva's role is limited to providing a platform for connecting users and facilitating agreements, and we disclaim all liability for any direct, indirect, or consequential damages resulting from your use of the platform.
            </p>

            <p className="mb-3  text-gray-700 text-xl">
                Governing Law and Jurisdiction
            </p>

            <p className="mb-3  text-gray-700 text-justify">
                This Agreement shall be governed by and construed in accordance with the laws of the State of Illinois, United States, without regard to its conflict of law provisions. 
                Any disputes arising out of or relating to this Agreement or your use of the platform shall be resolved exclusively in the federal or state courts located in Illinois, United States. By using Subliva, you consent to the jurisdiction of such courts and waive any objection to such venue.
            </p>

            <p className="mb-3  text-gray-700 text-xl">
                International Users
            </p>

            <p className="mb-3  text-gray-700 text-justify">
                If you are accessing or using the platform from outside the United States, you agree that you are responsible for complying with the laws of your jurisdiction. 
                Subliva makes no representation that the platform or its content is appropriate or available for use in other locations. Accessing the platform from territories where its contents are illegal is prohibited. Those who choose to access the platform from other locations do so on their own initiative and are responsible for compliance with local laws.
            </p>

            <div className="w-full flex justify-end">
                <div className="flex items-center gap-3 cursor-pointer bg-blue-600 w-fit text-white px-4 py-2 rounded" onClick={() => navigate(-1)}>
                    <FaArrowLeft />
                    Back
                </div>
            </div>
        </div>
    )
}

export default Terms;