import useEmblaCarousel from "embla-carousel-react";
import "./emblaCarousel.css";
import { DotButton, useDotButton } from "./emblaCarouselDotButton";
import {
  PrevButton,
  NextButton,
  usePrevNextButtons,
} from "./emblaCarouselArrowButtons";
import { Link } from "react-router-dom";
import Sample from "../../Images/image.png"

const EmblaCarousel = (props) => {
  const { slides, options } = props;
  const [emblaRef, emblaApi] = useEmblaCarousel(options);

  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi);

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);

  return (
    <section className="embla">
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">
          {slides.map((slide, index) => (
            <div className="featured_card_slide" key={index}>
              <Link to={`/property/${slide.property_id || slide.id}`}>
              <div className="category-card">
                <img src={slide.featured_image} alt="Apartments"/>
                <h3>{slide.property_name}</h3>
                <p>{slide.description.length > 100 ? `${slide.description.slice(0, 97)}...` : slide.description}</p>
              </div>
              </Link>
            </div>
          ))}
        </div>
        <div className="embla__controls">
          <div></div>
          <div className="embla__dots">
            {scrollSnaps.map((_, index) => (
              <DotButton
                key={index}
                onClick={() => onDotButtonClick(index)}
                className={`embla__dot${
                  index === selectedIndex ? " embla__dot--selected" : ""
                }`}
              />
            ))}
          </div>
          <div className="embla__buttons">
            <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
            <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default EmblaCarousel;
