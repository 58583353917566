import React, { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from './firebaseconfig';
import { doc, getDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import { useNavigate,Link } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import './dashboard.css';
import { useSnackbar } from "notistack";

const stripePromise = loadStripe('pk_test_51QPUHdIz5iHsStSgHzSSf5DzLZcDMcJS0SJYbowUKU39t8A8QUNxgYTDayFoW7kefSXzLwOA7lx2zKUT7kjRnwx900QZmcf89a');

const Dashboard = () => {
  const [contracts, setContracts] = useState([]);
  const [propertyDetails, setPropertyDetails] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedContract, setSelectedContract] = useState(null);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const navigate = useNavigate();
  const currentUser = auth.currentUser;
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setIsLoggedIn(true);
        await fetchUserContracts(user.uid);
      } else {
        setIsLoggedIn(false);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const fetchUserContracts = async (userId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/contracts/${userId}`);
      const data = await response.json();
      if (response.ok) {
        setContracts(data.contracts || []);
        await fetchPropertyDetails(data.contracts || []);
      } else {
        console.error('Error fetching contracts:', data.error);
      }
    } catch (error) {
      console.error('Error fetching contracts:', error);
    }
  };

  const fetchPropertyDetails = async (contracts) => {
    const details = {};
    for (const contract of contracts) {
      if (contract.propertyId) {
        try {
          const propertyRef = doc(db, 'properties', contract.propertyId);
          const propertySnap = await getDoc(propertyRef);
          if (propertySnap.exists()) {
            details[contract.propertyId] = propertySnap.data();
          }
        } catch (error) {
          console.error(`Error fetching property details for ID ${contract.propertyId}:`, error);
        }
      }
    }
    setPropertyDetails(details);
  };

  const handlePayNow = (contract) => {
    setSelectedContract(contract);
    setIsPaymentModalOpen(true);
  };

  const handlePaymentSuccess = async () => {
    try {
      const contractRef = doc(db, 'contracts', selectedContract.id);
      const paymentAmount =
        selectedContract.contractType === 'split'
          ? selectedContract.originalLeasingPrice - selectedContract.offerPrice
          : selectedContract.offerPrice;

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/pay-owner`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          contractId: selectedContract.id,
          ownerId: selectedContract.ownerId,
          amount: paymentAmount,
        }),
      });

      if (!response.ok) {
        throw new Error('Payment processing failed.');
      }

      await updateDoc(contractRef, { paymentStatus: true });

      enqueueSnackbar({
        variant: "success",
        message: "Payment successful! The owner has been paid.",
      });
      setIsPaymentModalOpen(false);
      await fetchUserContracts(currentUser.uid);
    } catch (error) {
      console.error('Error updating payment status after payment:', error);
      alert('Payment failed. Please try again.');
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="max-w-[768px] m-auto pt-28 pb-12">
      <h2 className=' text-[2.5rem] text-[#2a2a2a] text-center mb-6'>My Subleases</h2>
      {isLoggedIn ? (
        <div className="contracts-list min-h-[60vh]">
        {contracts.length === 0 && (
          <div className="no-contracts">
            <p>You have no active contracts. Explore available sublease options!</p>
            <Link
            className="redirect-button"
            to="/search"
            >
              Browse Subleases
              </Link>
              </div>
            )}
          {contracts.map((contract) => {
            const isOwner = contract.ownerId === currentUser.uid;
            const property = propertyDetails[contract.propertyId];
            const paymentAmount =
              contract.contractType === 'split'
                ? contract.originalLeasingPrice - contract.offerPrice
                : contract.offerPrice;

            return (
              <div
                key={contract.id}
                className={`contract-card ${isOwner ? 'owner-card' : ''}`}
              >
                {property && (
                  <div className="property-details">
                    <img
                      src={property.featured_image || 'placeholder.jpg'}
                      alt="Property"
                      className="property-image"
                    />
                    <h3>{property.property_name || 'Unknown Property'}</h3>
                    <p>{property.address || 'No address available'}</p>
                  </div>
                )}

                <div className="contract-info">
                  <h4>Contract ID: {contract.id}</h4>
                  <p>Buyer ID: {contract.buyerId}</p>
                  <p>Seller ID: {contract.ownerId}</p>
                  <p>Status: {contract.status}</p>
                  <p>
                    Contract Type: <strong>{contract.contractType}</strong>
                  </p>
                </div>

                {isOwner && contract.contractType === 'split' && (
                  <div className="owner-actions">
                    <p>Payment Status: {contract.paymentStatus ? 'Paid' : 'Pending'}</p>
                    <p>Amount Due: ${paymentAmount}</p>
                    {contract.paymentStatus === "false" && (
                      <button onClick={() => handlePayNow(contract)}>Pay the difference</button>
                    )}
                  </div>
                )}

                {!isOwner && contract.contractType !== 'split' && (
                  <div className="buyer-actions">
                    <p>Balance: ${contract.paymentStatus ? 0 : paymentAmount}</p>
                    {!contract.paymentStatus && (
                      <button onClick={() => handlePayNow(contract)}>Pay Now</button>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <p>Please log in to view your contracts.</p>
      )}

      {isPaymentModalOpen && (
        <div className="payment-modal">
          <h3>
            Pay $
            {selectedContract?.contractType === 'split'
              ? selectedContract.originalLeasingPrice - selectedContract.offerPrice
              : selectedContract?.offerPrice || 0}
          </h3>
          <Elements stripe={stripePromise}>
            <CheckoutForm
              amount={
                selectedContract?.contractType === 'split'
                  ? (selectedContract.originalLeasingPrice - selectedContract.offerPrice) * 100
                  : selectedContract?.offerPrice * 100
              }
              onSuccess={handlePaymentSuccess}
              onCancel={() => setIsPaymentModalOpen(false)}
            />
          </Elements>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
