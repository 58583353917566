import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { auth, db } from './firebaseconfig';
import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
  arrayUnion,
  collection,
  query,
  where,
  getDocs,
  addDoc,
  serverTimestamp,
  Timestamp,
} from 'firebase/firestore';
import './property.css';
import moment from 'moment';
import { onAuthStateChanged } from "firebase/auth";
import { MdOutlineContactMail } from "react-icons/md";
import { BsGrid } from "react-icons/bs";
import { FaCalendarAlt } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";


const PropertyDetails = () => {
  const { id } = useParams();
  const [property, setProperty] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSaved, setIsSaved] = useState(false);
  const [currentUser, setCurrentUser] = useState(null)
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user)
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const logUserVisit = async () => {
    if (!currentUser) return;
    const userDocRef = doc(db, 'userActivity', currentUser.uid);

    try {
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        await updateDoc(userDocRef, {
          visitedProperties: arrayUnion(id),
        });
      } else {
        await setDoc(userDocRef, { visitedProperties: [id] });
      }
    } catch (error) {
      console.error('Error logging property visit:', error);
    }
  };

  const saveProperty = async () => {
    if (!currentUser) {
      navigate('/signin');
      return;
    }
    const userDocRef = doc(db, 'users', currentUser.uid);
    try {
      const userDoc = await getDoc(userDocRef);
      if (!userDoc.exists()) {
        await setDoc(userDocRef, { savedProperties: [id] });
      } else {
        await updateDoc(userDocRef, { savedProperties: arrayUnion(id) });
      }
      
      setIsSaved(true);
    } catch (error) {
      console.error('Error saving property:', error);
    }
  };
  const startConversation = async (propertyId, propertyName, ownerId) => {
    if (!currentUser) {
      navigate('/signin');
      return;
    }

    try {
      // Query to check if a conversation already exists between the current user and the property owner
      const q = query(
        collection(db, 'conversations'),
        where('participants', 'array-contains', currentUser.uid),
        where('propertyId', '==', propertyId)
      );
      const existingConv = await getDocs(q);

      // If a conversation exists, navigate to it
      if (!existingConv.empty) {
        navigate(`/messages?conversation=${existingConv.docs[0].id}`);
        return;
      }

      // If no conversation exists, create a new one
      const conversationData = {
        participants: [currentUser.uid, ownerId],
        propertyId,
        propertyName,
        propertyOwner: ownerId, // Add the owner's ID
        propertyPrice: property.price, // Include the price from the current property
        propertyStatus: property.status,
        messages: [], // Initial empty messages array
        lastMessage: '',
        lastMessageTime: new Date(),
        unreadCount: {
          [currentUser.uid]: 0,
          [ownerId]: 0,
        },
      };

      // Add the new conversation to the "conversations" collection
      const conversationRef = await addDoc(collection(db, 'conversations'), conversationData);

      // Update both users' "messages" fields with the new conversation ID
      const currentUserRef = doc(db, 'users', currentUser.uid);
      const ownerUserRef = doc(db, 'users', ownerId);

      await updateDoc(currentUserRef, {
        messages: arrayUnion(conversationRef.id),
      });
      await updateDoc(ownerUserRef, {
        messages: arrayUnion(conversationRef.id),
      });

      // Navigate to the newly created conversation
      navigate(`/messages?conversation=${conversationRef.id}`);
    } catch (error) {
      console.error('Error starting conversation:', error);
    }
  };
  useEffect(() => {
    const fetchProperty = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/properties/${id}`);
        const data = await response.json();
        if (response.ok) {
          data.createdAt = new Timestamp(data.createdAt.seconds, data.createdAt.nanoseconds);
          if (data.updatedAt) {
            data.updatedAt = new Timestamp(data.updatedAt.seconds, data.updatedAt.nanoseconds);
          }
          if (typeof (data.gallery_images) === "string") {
            data.gallery_images = JSON.parse(data.gallery_images.replace(/'/g, '"'))
          }
          console.log("data::::", data)
          setProperty(data);
          logUserVisit();
        }
      } catch (error) {
        console.error('Error fetching property:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchProperty();
  }, [id]);

  if (loading) return <p>Loading property details...</p>;
  if (!property) return <p>Property not found.</p>;

  return (
    <div className="min-h-screen max-w-[1024px] max-[1044px]:px-4 m-auto pt-28 pb-12">
      {/* Header Section */}
      <div className="text-center mb-4">
        <h1 className="text-3xl font-bold text-gray-800">{property.property_name}</h1>
      </div>

      {/* Save Property or Contact Owner Section */}
      {currentUser?.uid !== property.owner && (
        <div className="flex justify-end space-x-2 mb-2">
          <div className='flex gap-4 mb-2'>
            <div className={`flex items-center gap-2  px-4 py-1 rounded cursor-pointer hover:bg-gray-200 ${isSaved === true ? 'bg-gray-200' : 'bg-white'}`} onClick={saveProperty}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                // fill='currentColor'
                fill={isSaved === true ? 'currentColor' : 'none'}
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4.318 6.318a4.5 4.5 0 016.364 0l.318.318.318-.318a4.5 4.5 0 016.364 6.364L12 21.364l-7.682-8.682a4.5 4.5 0 010-6.364z"
                />
              </svg>
              <span>Saved</span>
            </div>
            <div className='flex items-center gap-2 bg-white px-4 py-1 rounded cursor-pointer hover:bg-gray-200'
              onClick={() =>
                startConversation(property.id, property.property_name, property.owner)
              }
            >
              <MdOutlineContactMail size={20} />
              <span>Contact</span>
            </div>
            {/* <div className='flex items-center gap-2 bg-white px-4 py-1 rounded cursor-pointer hover:bg-gray-200'>
              <BsGrid size={20} />
              <span>See all photos</span>
            </div> */}
          </div>
        </div>
      )}

      {/* Image part */}
      <div className='flex items-center justify-between w-full h-96 gap-3 rounded overflow-hidden'>
  {property.featured_image && (
    <div className="w-3/5 h-full">
      <a href={property.featured_image} target="_blank" rel="noopener noreferrer">
        <img
          src={property.featured_image}
          alt={property.property_name}
          className="w-full h-full object-cover rounded shadow-md"
        />
      </a>
    </div>
  )}
  {property.gallery_images && property.gallery_images.length > 0 && (
    <div className='w-2/5 h-full'>
      <div className='grid grid-cols-1 grid-rows-2 md:grid-cols-2 gap-3'>
        {property.gallery_images.slice(0, 4).map((image, index) => (
          <div className='col-span-1 row-span-1 h-48' key={index}>
            <a href={image} target="_blank" rel="noopener noreferrer">
              <img
                src={image}
                alt={`Gallery ${index + 1}`}
                className="w-full h-full md:h-45 object-cover shadow-md"
              />
            </a>
          </div>
        ))}
      </div>
    </div>
  )}
</div>


      {/* Property Information Table */}
      <div className="property-info bg-white p-6 rounded-lg shadow-lg mt-4 text-gray-700">
        <div className='pb-4'>
            <div className='text-lg font-semibold'>{property.property_name}</div>
            <div className='text-gray-400 text-sm font-semibold'>
              <span className={`${property.status === "featured" ? "text-green-600" : property.status === "sold" ? "text-blue-600" : "text-green-500" }`}>{property.status.toUpperCase()}</span>
              {` · ${property.bedroom} Bed · ${property.bathroom} Bath · ${property.area_size || 'N/A'} sqft`}
            </div>
          </div>
          <div className='border-y py-4'>
            {property.description}
          </div>
          <div className='border-b py-4'>
            <div className='font-bold mb-2'>Rent</div>
            <div className='text-lg font-semibold mb-2 text-gray-500'>{`$${property.price.toLocaleString()} /month`}</div>
          </div>
          <div className='border-b py-4'>
            <div className='font-bold mb-2'>Availability</div>
            <div className='flex items-center gap-6'>
              <div><FaCalendarAlt /></div>
              <div>
                <div className='font-semibold text-sm'>From</div>
                <div className='text-gray-400 text-sm font-semibold'>{property.availabilityStart ? moment.unix(property?.availabilityStart?.seconds).format('MM-DD-YYYY') : "N/A"}</div>
              </div>
              <div>
                <div className='font-semibold text-sm'>End</div>
                <div className='text-gray-400 text-sm font-semibold'>{property.availabilityEnd ? moment.unix(property?.availabilityEnd?.seconds).format('MM-DD-YYYY') : "N/A"}</div>
              </div>
            </div>
          </div>
          <div className='border-b py-4'>
            <div className='font-bold mb-2'>Location</div>
            <div className='flex gap-2 items-center'>
              <div><FaLocationDot /></div>
              <div>{property.address}, {property.city}, {property.state}, {property.zip_code}</div>
            </div>
          </div>
      </div>

      <div className='h-[300px] rounded mt-4'>
        <iframe
          src={`https://www.google.com/maps/embed/v1/search?key=AIzaSyAmtpmge5OnqAfzmyIuSkWLpWrFQkhhz9Y&q=${property.address}, ${property.city}`}
          title="Property Map"
          className="w-full h-full"
        ></iframe>
      </div>

    </div>
  );
};

export default PropertyDetails;
