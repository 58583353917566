import { useClickAway } from "react-use";
import { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "./firebaseconfig";
import { useState, useEffect } from "react";
import { Squash as Hamburger } from "hamburger-react";
import { AnimatePresence, motion } from "framer-motion";
import { onAuthStateChanged, signOut } from "firebase/auth";

import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react'

const NavMobile = () => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [openComming, setOpenComming] = useState(false)

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setIsAuthenticated(!!user);
        });

        return () => {
            unsubscribe();
        };
    }, []);
    const routes = [
        {
            title: "Subleases",
            href: "/search",
        },
        {
            title: "List a Property",
            href: "/list",
        },
        {
            title: "Find a Roommate",
            href: "/roommate",
        },
        {
            title: "Blogs",
            href: "/blogs",
        },
        {
            title: "Help",
            href: "/help",
        },
        {
            title: "About",
            href: "/about",
        },
    ]
    const accountLabel = [
        {
            title: "Settings",
            href: "/settings"
        },
        {
            title: "Messages",
            href: "/messages"
        },
        {
            title: "My Listings",
            href: "/properties"
        },
        {
            title: "My Subleases",
            href: "/dashboard"
        },
        {
            title: "Saved Properties",
            href: "/saved"
        }
    ]

    const handleSignOut = async () => {
        try {
          await signOut(auth);
          navigate("/");
        } catch (error) {
          console.error("Error signing out:", error);
        }
    };

    const handleClickRoutes = async (label) => {
        if (label.title === "Find a Roommate") {
            setOpenComming(true)
        } else {
            navigate(label.href)
        }
        setIsOpen((prev) => !prev)
    }

    return (
        <div className="hidden max-[950px]:block">
            <Hamburger toggled={isOpen} size={20} toggle={setIsOpen} />
            {isOpen && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    className="fixed w-[200px] shadow-4xl right-0 top-[5rem] px-4 pb-3 pt-0 bg-white rounded-b"
                >
                    <ul className="grid">
                        {routes.map((route, idx) => {
                            const { Icon } = route;

                            return (
                                <motion.li
                                    initial={{ scale: 0, opacity: 0 }}
                                    animate={{ scale: 1, opacity: 1 }}
                                    transition={{
                                        type: "spring",
                                        stiffness: 260,
                                        damping: 20,
                                        delay: 0.1 + idx / 10,
                                    }}
                                    key={route.title}
                                    className="w-full"
                                >
                                    <div
                                        onClick={() => handleClickRoutes(route)}
                                        className="flex items-center justify-between w-full py-1 rounded-xl "
                                    >
                                        <span className="flex gap-1 text-gray-700 text-sm hover:text-blue-600">{route.title}</span>
                                    </div>
                                </motion.li>
                            );
                        })}
                    </ul>
                    {
                        !isAuthenticated ? <motion.div initial={{ scale: 0, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            transition={{
                                type: "spring",
                                stiffness: 260,
                                damping: 20,
                                delay: 0.1 + 5 / 10,
                            }}
                            onClick={() => navigate("/signin")}
                        >
                            Log In
                        </motion.div> : <div>
                            <ul>
                                {
                                    accountLabel.map((label, index) => {
                                        return (
                                            <motion.li
                                                initial={{ scale: 0, opacity: 0 }}
                                                animate={{ scale: 1, opacity: 1 }}
                                                transition={{
                                                    type: "spring",
                                                    stiffness: 260,
                                                    damping: 20,
                                                    delay: 0.1 + (index + 4) / 10,
                                                }}
                                                key={label.title}
                                            >
                                                <a href={label.href} class="block py-2 text-gray-700 text-sm hover:text-blue-600 hover:underline">
                                                    {label.title}
                                                </a>
                                            </motion.li>
                                        )
                                    })
                                }
                            </ul>
                            <motion.div 
                                initial={{ scale: 0, opacity: 0 }}
                                animate={{ scale: 1, opacity: 1 }}
                                transition={{
                                    type: "spring",
                                    stiffness: 260,
                                    damping: 20,
                                    delay: 0.1 + 10 / 10,
                                }}
                                className="border-t pt-2 text-gray-700 text-sm"
                                onClick={handleSignOut}
                            >
                                Sign Out
                            </motion.div>
                        </div>
                    }

                </motion.div>
            )}
            <Dialog open={openComming} onClose={() => setOpenComming(false)} className="relative z-10">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <p className=" text-gray-800 font-bold text-center text-3xl">
                  Coming Soon
                </p>
              </div>
              <div className="px-4 pb-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  data-autofocus
                  onClick={() => setOpenComming(false)}
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                  Cancel
                </button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
        </div>
    )
}

export default NavMobile;