import React, { useState, useEffect } from 'react';
import { auth, db } from './firebaseconfig'; // Adjust the import path as needed
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'axios';
import {
  collection,
  getDocs,
  doc,
  deleteDoc,
  setDoc,
  updateDoc,
  getDoc,
} from 'firebase/firestore';

const AdminPage = () => {
  const [user, setUser] = useState(null);
  const [pendingLeases, setPendingLeases] = useState([]);
  const [loading, setLoading] = useState(true);
  const [entryDenied, setEntryDenied] = useState(false);

  // Admin UID
  const adminUID = 'iankQOyiyYfDUChVKbcC3otq8al2';

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        if (currentUser.uid === adminUID) {
          setUser(currentUser);
          fetchPendingLeases();
        } else {
          setEntryDenied(true);
          setLoading(false);
        }
      } else {
        setEntryDenied(true);
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchPendingLeases = async () => {
    try {
      const leasesRef = collection(db, 'leases');
      const querySnapshot = await getDocs(leasesRef);
      const leases = querySnapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .filter((lease) => lease.status === 'pending');

      // Fetch property details for each lease
      const leasesWithProperties = await Promise.all(
        leases.map(async (lease) => {
          const propertyRef = doc(db, 'pending', lease.propertyId);
          const propertySnap = await getDoc(propertyRef);
          if (propertySnap.exists()) {
            return { ...lease, property: propertySnap.data() };
          }
          return lease;
        })
      );

      setPendingLeases(leasesWithProperties);
    } catch (error) {
      console.error('Error fetching pending leases:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleAccept = async (lease) => {
    try {
      const propertyId = lease.propertyId;

      // Send approval email
      const emailData = {
        to: lease.userEmail,
        subject: 'Your Sublease Listing is Live on Subliva! 🎉',
        text: `Dear ${lease.userEmail},
      
Congratulations! Your sublease listing has been successfully verified and is now live on Subliva. 🎉 
      
Here’s what’s next:
      
- Your Listing is Active: Potential sublessees can now view your listing and reach out to you directly.
- Get Ready for Inquiries: Keep an eye on your inbox for messages from interested parties.
      
We’re dedicated to providing a smooth and secure experience for all our users. If you need assistance or have any questions, don’t hesitate to reach out to our support team at support@subliva.com.
      
Thank you for choosing Subliva to manage your sublease!
      
Best regards,  
The Subliva Team
      `,
      };
      

      console.log('Email data being sent:', emailData);
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/send-email`, emailData);

      // Handle property and lease update
      const pendingPropertyRef = doc(db, 'pending', propertyId);
      const pendingPropertySnapshot = await getDoc(pendingPropertyRef);

      if (pendingPropertySnapshot.exists()) {
        const propertyData = pendingPropertySnapshot.data();
        const propertiesRef = doc(db, 'properties', propertyId);
        await setDoc(propertiesRef, {
          ...propertyData,
          status: 'verified',
        });
        await deleteDoc(pendingPropertyRef);
      }

      // Update lease status to 'approved'
      const leaseRef = doc(db, 'leases', lease.id);
      await updateDoc(leaseRef, { status: 'approved' });

      setPendingLeases(pendingLeases.filter((l) => l.id !== lease.id));
      alert('Lease accepted and property moved to properties.');
    } catch (error) {
      console.error('Error accepting lease:', error);
      alert('Failed to accept lease.');
    }
  };

  const handleReject = async (lease) => {
    try {
      const emailData = {
        to: lease.userEmail,
        subject: 'Important Update Regarding Your Property Listing',
        text: `Dear ${lease.userEmail},
        
Thank you for submitting your property (${lease.propertyId}) for listing on our platform. After careful review, we regret to inform you that your property does not meet the requirements for approval at this time.
      
If you would like to understand the reasons for this decision or need further assistance, please reply to this email. Our team will be happy to provide additional details and guide you on the next steps.
      
Best regards,  
The Subliva Team`
      };

      console.log('Email data being sent:', emailData);
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/send-email`, emailData);

      // Handle property and lease update
      const leaseRef = doc(db, 'leases', lease.id);
      await updateDoc(leaseRef, { status: 'rejected' });

      const pendingPropertyRef = doc(db, 'pending', lease.propertyId);
      await deleteDoc(pendingPropertyRef);

      setPendingLeases(pendingLeases.filter((l) => l.id !== lease.id));
      alert('Lease rejected and property removed from pending.');
    } catch (error) {
      console.error('Error rejecting lease:', error);
      alert('Failed to reject lease.');
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (entryDenied) {
    return <p>Access Denied</p>;
  }

  return (
    <div>
  <h2>Pending Leases</h2>
  {pendingLeases.length === 0 ? (
    <p>No pending leases.</p>
  ) : (
    pendingLeases.map((lease) => (
      <div
        key={lease?.id}
        style={{ border: '1px solid black', padding: '10px', margin: '10px' }}
      >
        <h3>Lease ID: {lease?.id || 'N/A'}</h3>
        <p>Property ID: {lease?.propertyId || 'N/A'}</p>
        {lease?.property && (
          <>
            <h4>Property Details</h4>
            <p>Property Name: {lease.property?.property_name || 'N/A'}</p>
            <p>Address: {lease.property?.address || 'N/A'}</p>
            <p>City: {lease.property?.city || 'N/A'}</p>
            <p>Price: ${lease.property?.price || 'N/A'}</p>
            <p>Description: {lease.property?.description || 'N/A'}</p>
            {lease.property?.featured_image ? (
              <img
                src={lease.property?.featured_image}
                alt={`Featured image`}
                style={{
                  width: '100px',
                  height: '100px',
                  objectFit: 'cover',
                  borderRadius: '5px',
                }}
              />
            ) : (
              <p>No featured image available.</p>
            )}
            {lease.property?.gallery_images?.length > 0 && (
              <div>
                <h5>Gallery Images:</h5>
                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    flexWrap: 'wrap',
                  }}
                >
                  {lease.property?.gallery_images?.map((image, index) => (
                    <img
                      key={index}
                      src={image}
                      alt={`Property ${index + 1}`}
                      style={{
                        width: '100px',
                        height: '100px',
                        objectFit: 'cover',
                        borderRadius: '5px',
                      }}
                    />
                  ))}
                </div>
              </div>
            )}
          </>
        )}
        <p>
          Uploaded At:{' '}
          {lease?.uploadedAt ? lease.uploadedAt.toDate().toString() : 'N/A'}
        </p>
        <p>Owner: {lease.property?.ownerEmail || 'N/A'}</p>
        <p>Proof of Lease:</p>
        {lease?.proofOfLeaseURL ? (
          <a
            href={lease?.proofOfLeaseURL}
            target="_blank"
            rel="noopener noreferrer"
          >
            View Proof of Lease
          </a>
        ) : (
          <p>No proof of lease file available.</p>
        )}
        <div style={{ marginTop: '10px' }}>
          <button onClick={() => handleAccept(lease)}>Approve</button>
          <button onClick={() => handleReject(lease)}>Reject</button>
        </div>
      </div>
    ))
  )}
</div>

  );
};

export default AdminPage;
