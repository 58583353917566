import React from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

const CheckoutForm = ({ amount, onSuccess, onCancel }) => {
  const stripe = useStripe();
  const elements = useElements();

  const fetchPaymentIntent = async (amount) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/stripe/create-payment-intent`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ amount }),
    });
    const data = await response.json();
    return data.clientSecret; // Return the client secret
};

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    try {
      const clientSecret = await fetchPaymentIntent(10);
      const cardElement = elements.getElement(CardElement);
      const { error, paymentIntent } = await stripe.confirmCardPayment(
        clientSecret, // Replace with the client secret from your backend
        {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: 'John Doe', // Replace with dynamic user details
            },
          },
        }
      );

      if (error) {
        console.error('Payment error:', error);
        alert('Payment failed. Please try again.');
      } else if (paymentIntent && paymentIntent.status === 'succeeded') {
        alert('Payment successful!');
        onSuccess();
      }
    } catch (error) {
      console.error('Error processing payment:', error);
      alert('Payment failed. Please try again.');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement className='border px-4 py-2 rounded' />
      <div className='flex gap-4 justify-end mt-6'>
        <button type="submit" disabled={!stripe} className='bg-blue-500 hover:bg-blue-600 text-white px-3 py-1 rounded'>
          Pay ${amount / 100}
        </button>
        <button type="button" onClick={onCancel} className='rounded px-3 py-1 bg-gray-300 hover:bg-gray-400'>
          Cancel
        </button>

      </div>
    </form>
  );
};

export default CheckoutForm;
