// Navbar.js
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "./firebaseconfig";
import { onAuthStateChanged, signOut } from "firebase/auth";
import './Navbar.css';
import NavMobile from "./nav-mobile";
import OutsideClickHandler from "react-outside-click-handler";
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'

const Navbar = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showAccount, setShowAccount] = useState(false)
  const navigate = useNavigate();
  const [openComming, setOpenComming] = useState(false)
  const adminUID = 'iankQOyiyYfDUChVKbcC3otq8al2';
  const [ad,setad] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuthenticated(true);
        setad(user.uid === adminUID); // Use `===` for strict equality
      } else {
        setIsAuthenticated(false);
        setad(false);
      }
    });
    
    
    return () => {
      unsubscribe();
    };
  }, []);
  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate("/");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return (
    <nav
    className={`navbar fixed top-0 w-full z-20 transition-all duration-300 px-8 py-4 max-[860px]:px-3 navbar-default`}
  >
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto">
        {/* Logo and Name */}
        <Link
          to="/"
          className="flex items-center space-x-3 text-gray-800"
        >
          <img
            src="/images/logo/favicon.jpg" 
            alt="Subliva Logo"
            className="w-8 h-8 object-cover"
          />
          <span className="self-center text-2xl font-semibold whitespace-nowrap">
            Subliva
          </span>
        </Link>

        {/* Navbar Links */}
        <div
          className="items-center justify-between max-[950px]:hidden"
          id="navbar-sticky"
        >
          <ul className="flex gap-12 max-[1024px]:gap-6 max-[860px]:gap-3">
            <li>
              <Link
                to="/search"
                className="block text-base py-2 px-3 font-bold rounded text-gray-800 hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-600"
              >
                Subleases
              </Link>
            </li>
            {isAuthenticated && (
              <li>
                <Link
                  to="/list"
                  className="block text-base py-2 px-3 font-bold rounded text-gray-800 hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-600"
                >
                  List a Property
                </Link>
              </li>
            )}
            
            <li>
              <Link
                to="/blogs"
                className="block text-base py-2 px-3 font-bold rounded text-gray-800 hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-600"
              >
                Blogs
              </Link>
            </li>
            <li>
              <Link
                to="/help"
                className="block text-base py-2 px-3 font-bold rounded text-gray-800 hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-600"
              >
                Help
              </Link>
            </li>
            <li>
              <Link
                to="/about"
                className="block text-base py-2 px-3 font-bold rounded text-gray-800 hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-600"
              >
                About
              </Link>
            </li>
            {ad && (
              <Link
              to="/admin"
              className="block text-base py-2 px-3 font-bold rounded text-gray-800 hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-600"
            >
              Admin
            </Link>
            )}
          </ul>
        </div>

        {/* Right-side Buttons */}
        <div className="flex max-[950px]:hidden">
          {!isAuthenticated ? (
            <div className="py-2 text-gray-800 font-bold cursor-pointer hover:text-blue-600 hover:underline" onClick={() => navigate("/signin")}>
              Log In
            </div>
          ) : (
            <OutsideClickHandler
              onOutsideClick={() => {
                setShowAccount(false)
              }}
            >
              <div className="relative">
                <button 
                  class="text-gray-800 hover:text-blue-600 shadow-none focus:outline-none font-bold rounded text-base px-3 py-2 text-center inline-flex items-center mt-0" type="button"
                  onClick={() => setShowAccount(!showAccount)}
                >
                  My Account
                  <svg class="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
                  </svg>
                </button>
                {
                  showAccount === true && 
                  <div class="bg-white rounded-lg shadow w-44 absolute right-0">
                    <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
                      <li>
                        <Link to="/settings" className="block text-gray-700 px-4 py-2 hover:text-blue-600 hover:underline">
                          Settings
                        </Link>
                      </li>
                      <li>
                        <Link to="/messages" className="block text-gray-700 px-4 py-2 hover:text-blue-600 hover:underline">
                          Messages
                        </Link>
                      </li>
                      <li>
                        <Link to="/properties" className="block text-gray-700 px-4 py-2 hover:text-blue-600 hover:underline">
                          My Listings
                        </Link>
                      </li>
                      <li>
                        <Link to="/dashboard" className="block text-gray-700 px-4 py-2 hover:text-blue-600 hover:underline">
                          My Subleases
                        </Link>
                      </li>
                      <li>
                        <Link to="/saved" className="block text-gray-700 py-2 px-4 hover:text-blue-600 hover:underline">
                          Saved Properties
                        </Link>
                      </li>
                    </ul>
                    <div class="py-2 border-teal-300">
                      <div href="#" class="block px-4 text-sm text-gray-700 hover:text-blue-600 hover:underline cursor-pointer" onClick={handleSignOut}>Sign out</div>
                    </div>
                  </div>
                }
                
              </div>
            </OutsideClickHandler>
          )}
        </div>
        <NavMobile />
      </div>
      <Dialog open={openComming} onClose={() => setOpenComming(false)} className="relative z-10">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <p className=" text-gray-800 font-bold text-center text-3xl">
                  Coming Soon
                </p>
              </div>
              <div className="px-4 pb-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  data-autofocus
                  onClick={() => setOpenComming(false)}
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                  Cancel
                </button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </nav>
  );
  
};

export default Navbar;
