import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth, db } from './firebaseconfig';
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
  serverTimestamp,
} from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm'; // Import your CheckoutForm
import './MyListings.css';
import { FaPlus } from "react-icons/fa";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { useSnackbar } from "notistack";

const stripePromise = loadStripe('pk_test_51QPUHdIz5iHsStSgHzSSf5DzLZcDMcJS0SJYbowUKU39t8A8QUNxgYTDayFoW7kefSXzLwOA7lx2zKUT7kjRnwx900QZmcf89a');

const MyListings = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);
  const [error, setError] = useState(null);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentListing, setCurrentListing] = useState(null);

  const [bedrooms, setBedrooms] = useState('');
  const [bathrooms, setBathrooms] = useState('');
  const [areaSize, setAreaSize] = useState('');
  const [price, setPrice] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
      } else {
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    const fetchListings = async () => {
      if (!currentUser) {
        setLoading(false);
        return;
      }

      try {
        const propertiesRef = collection(db, 'properties');
        const pendingRef = collection(db, 'pending');

        const propertiesQuery = query(
          propertiesRef,
          where('owner', '==', currentUser.uid)
        );

        const pendingQuery = query(
          pendingRef,
          where('owner', '==', currentUser.uid)
        );

        const [propertiesSnapshot, pendingSnapshot] = await Promise.all([
          getDocs(propertiesQuery),
          getDocs(pendingQuery),
        ]);

        const propertiesData = propertiesSnapshot.docs.map((doc) => ({
          id: doc.id,
          status: 'verified',
          collection: 'properties',
          ...doc.data(),
        }));

        const pendingData = pendingSnapshot.docs.map((doc) => ({
          id: doc.id,
          status: 'pending',
          collection: 'pending',
          ...doc.data(),
        }));

        setListings([...propertiesData, ...pendingData]);
      } catch (err) {
        console.error('Error fetching listings:', err);
        setError('Failed to load listings. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchListings();
  }, [currentUser]);

  const handleFeatureClick = (listing) => {
    setCurrentListing(listing);
    setIsPaymentModalOpen(true);
  };
  const ListingCard = ({ listing, handleEditClick }) => (
    <div
      key={listing.id}
      className="listing-card bg-white flex rounded-lg shadow-lg hover:shadow-xl transition-shadow overflow-hidden"
    >
      <div className="w-1/3 max-[768px]:w-full h-[200px] max-[768px]:h-[150px]">
        {listing.featured_image ? (
          <img
            src={listing.featured_image}
            alt={listing.property_name}
            className="w-full h-full object-cover"
          />
        ) : (
          <div>No Image Available</div>
        )}
      </div>
  
      <div className="py-2 px-4 w-2/3 max-[768px]:w-full">
        <h2 className="text-2xl font-bold text-gray-800 truncate">
          {listing.property_name}
        </h2>
        <p className="text-sm text-gray-600 mt-2 truncate">
          {listing.address}, {listing.city}
        </p>
        <div className="flex gap-2 text-sm text-gray-700 mt-2">
          <span>{listing.bedroom || 0} Beds</span>
          <div className='w-[1px] border border-gray-700'></div>
          <span>{listing.bathroom || 0} Baths</span>
          <div className='w-[1px] border border-gray-700'></div>
          <span>{listing.area_size || 0} sqft</span>
        </div>
        <p className="text-xl font-semibold text-blue-600 mt-2">
          ${listing.price}
        </p>
        <div className="tex-[#555] mt-2 flex items-center">
          <p>
            Status:{' '}
            <span className={`${listing.status === 'featured' ? 'text-yellow-500' : listing.status === 'Active' ? 'text-red-500' : 'text-green-500'}`}>
              {listing.status}
            </span>
          </p>
          {listing.status === 'featured' && (
            <span className="ml-2 text-yellow-500 text-lg">⭐</span>
          )}
        </div>
  
        <div className="listing-actions flex justify-between items-center mt-2">
          <Link
            to={`/property/${listing.id}`}
            className="text-sm text-blue-600 hover:underline"
          >
            View Details
          </Link>
          <button
            className="text-sm text-gray-500 hover:text-gray-800"
            onClick={() => handleEditClick(listing)}
          >
            Edit Listing
          </button>
          {listing.status === 'verified' && (
            <button
              className="text-sm text-green-500 hover:underline"
              onClick={() => handleFeatureClick(listing)}
            >
              Feature Listing
            </button>
          )}
        </div>
      </div>
    </div>
  );
  
  const handlePaymentSuccess = async () => {
    try {
      const { id, collection } = currentListing;

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/mark-featured`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ listingId: id, collectionName: collection }),
      });

      if (!response.ok) {
        throw new Error('Failed to mark listing as featured');
      }

      setListings((prevListings) =>
        prevListings.map((item) =>
          item.id === id ? { ...item, status: 'featured' } : item
        )
      );

      setIsPaymentModalOpen(false);
      enqueueSnackbar({
        variant: "success",
        message: "Payment successful! Your listing is now featured.",
      });
    } catch (err) {
      console.error('Error updating listing:', err);
      setError('Failed to feature listing. Please try again.');
    }
  };

  const handleCancelPayment = () => {
    setIsPaymentModalOpen(false);
    setCurrentListing(null);
  };

  const handleEditClick = (listing) => {
    setCurrentListing(listing);
    setBedrooms(listing.bedroom || '');
    setBathrooms(listing.bathroom || '');
    setAreaSize(listing.area_size || '');
    setPrice(listing.price || '');
    setIsEditModalOpen(true);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    if (!currentListing) {
      setError('Listing data is not available.');
      return;
    }

    try {
      const { id, collection } = currentListing;
      const docRef = doc(db, collection, id);

      await updateDoc(docRef, {
        bedroom: parseInt(bedrooms) || 0,
        bathroom: parseInt(bathrooms) || 0,
        area_size: parseInt(areaSize) || 0,
        price: parseFloat(price) || 0,
        updatedAt: serverTimestamp(),
        status: "pending",
      });

      setListings((prevListings) =>
        prevListings.map((listing) =>
          listing.id === id
            ? {
                ...listing,
                bedroom: parseInt(bedrooms) || 0,
                bathroom: parseInt(bathrooms) || 0,
                area_size: parseInt(areaSize) || 0,
                price: parseFloat(price) || 0,
              }
            : listing
        )
      );

      setIsEditModalOpen(false);
    } catch (err) {
      console.error('Error updating listing:', err);
      setError('Failed to update listing. Please try again later.');
    }
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setCurrentListing(null);
    setError(null);
  };

  if (loading) {
    return <div className="loading">Loading your listings...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="max-w-[1024px] mx-auto pt-28 pb-12 max-[1044px]:px-4">
      <div className="listings-header flex justify-between items-center mb-8">
        <h1 className="text-[2.5rem] text-[#2a2a2a]">My Listings</h1>
        <Link
          to="/list"
          className="bg-blue-600 text-white flex items-center gap-3 py-2 px-6 rounded shadow hover:bg-blue-700 focus:ring-2 focus:ring-blue-400"
        >
          <FaPlus />
          <span>New Property</span>
        </Link>
      </div>

      {
  listings.length === 0 ? (
    <div className="no-listings text-center py-12 bg-white rounded-lg shadow-md">
      <p className="text-lg text-gray-600 mb-4">
        You haven't created any listings yet.
      </p>
      <Link
        to="/list"
        className="bg-blue-600 text-white py-3 px-6 rounded-lg shadow hover:bg-blue-700 focus:ring-2 focus:ring-blue-400"
      >
        Create Your First Listing
      </Link>
    </div>
  ) : (
    <Tabs>
      <TabList>
        <Tab><div className='text-blue-500 font-bold'>Featured</div></Tab>
        <Tab><div className='text-red-500 font-bold'>Sold</div></Tab>
        <Tab><div className='text-yellow-700 font-bold'>Pending</div></Tab>
        <Tab><div className='text-green-500 font-bold'>Verified</div></Tab>
      </TabList>

      <TabPanel>
        <div className='max-[500px]:px-6 min-h-[70vh]'>
          {listings.filter((list) => list.status === "featured").length === 0 ? (
            <p className="text-center text-gray-600">No Featured Listings</p>
          ) : (
            <div className="grid grid-cols-2 max-[500px]:grid-cols-1 gap-4">
              {listings.filter((list) => list.status === "featured").map((listing) => (
                <ListingCard listing={listing} handleEditClick={handleEditClick} />
              ))}
            </div>
          )}
        </div>
      </TabPanel>

      <TabPanel>
        <div className='max-[500px]:px-6 min-h-[55vh]'>
          {listings.filter((list) => list.status === "sold").length === 0 ? (
            <p className="text-center text-gray-600">No Sold Listings</p>
          ) : (
            <div className="grid grid-cols-2 max-[500px]:grid-cols-1 gap-4">
              {listings.filter((list) => list.status === "sold").map((listing) => (
                <ListingCard listing={listing} handleEditClick={handleEditClick} />
              ))}
            </div>
          )}
        </div>
      </TabPanel>

      <TabPanel>
        <div className='max-[500px]:px-6 min-h-[55vh]'>
          {listings.filter((list) => list.status === "pending").length === 0 ? (
            <p className="text-center text-gray-600">No Pending Listings</p>
          ) : (
            <div className="grid grid-cols-2 max-[500px]:grid-cols-1 gap-4">
              {listings.filter((list) => list.status === "pending").map((listing) => (
                <ListingCard listing={listing} handleEditClick={handleEditClick} />
              ))}
            </div>
          )}
        </div>
      </TabPanel>

      <TabPanel>
        <div className='max-[500px]:px-6 min-h-[55vh]'>
          {listings.filter((list) => list.status === "verified").length === 0 ? (
            <p className="text-center text-gray-600">No Verified Listings</p>
          ) : (
            <div className="grid grid-cols-2 max-[500px]:grid-cols-1 gap-4">
              {listings.filter((list) => list.status === "verified").map((listing) => (
                <ListingCard listing={listing} handleEditClick={handleEditClick} />
              ))}
            </div>
          )}
        </div>
      </TabPanel>
    </Tabs>
  )
}

      
      
      {isEditModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
            <h2 className="text-lg font-bold text-gray-800 mb-4">Edit Listing</h2>
            {error && (
              <div className="error text-red-500 text-sm mb-4">{error}</div>
            )}
            <form onSubmit={handleEditSubmit} className="space-y-4">
              <div className="form-group">
                <label className="block text-sm font-medium text-gray-700">
                  Bedrooms:
                </label>
                <input
                  type="number"
                  value={bedrooms}
                  onChange={(e) => setBedrooms(e.target.value)}
                  required
                  min="0"
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-400 focus:outline-none"
                />
              </div>
              <div className="form-group">
                <label className="block text-sm font-medium text-gray-700">
                  Bathrooms:
                </label>
                <input
                  type="number"
                  value={bathrooms}
                  onChange={(e) => setBathrooms(e.target.value)}
                  required
                  min="0"
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-400 focus:outline-none"
                />
              </div>
              <div className="form-group">
                <label className="block text-sm font-medium text-gray-700">
                  Square Footage:
                </label>
                <input
                  type="number"
                  value={areaSize}
                  onChange={(e) => setAreaSize(e.target.value)}
                  required
                  min="0"
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-400 focus:outline-none"
                />
              </div>
              <div className="form-group">
                <label className="block text-sm font-medium text-gray-700">
                  Price:
                </label>
                <input
                  type="number"
                  step="0.01"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  required
                  min="0"
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-400 focus:outline-none"
                />
              </div>
              <div className="modal-actions flex justify-between mt-6">
                <button
                  type="submit"
                  className="bg-blue-600 text-black py-2 px-4 rounded-lg hover:bg-blue-700 focus:ring-2 focus:ring-blue-400"
                >
                  Save Changes
                </button>
                <button
                  type="button"
                  className="bg-gray-300 text-gray-800 py-2 px-4 rounded-lg hover:bg-gray-400 focus:ring-2 focus:ring-gray-400"
                  onClick={closeEditModal}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

{isPaymentModalOpen && (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
      <h2 className="text-lg font-bold text-gray-800 mb-4">
        Feature Listing - $10
      </h2>
      <p className="text-gray-600 mb-4">
        Click the button below to complete your payment securely via Stripe.
      </p>
      <div className="flex justify-between">
        <a
          href="https://buy.stripe.com/fZeeXJev36H5bWE144"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 focus:ring-2 focus:ring-blue-400"
        >
          Proceed to Payment
        </a>
        <button
          onClick={handleCancelPayment}
          className="bg-gray-300 text-gray-800 py-2 px-4 rounded-lg hover:bg-gray-400 focus:ring-2 focus:ring-gray-400"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
)}

    </div>
  );
};

export default MyListings;
