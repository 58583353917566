import React, { useState, useEffect,useRef  } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import "./search.css";
import OutsideClickHandler from 'react-outside-click-handler';
import ReactPaginate from "react-paginate";
import ReactSlider from "react-slider";
import { FaMap, FaList  } from "react-icons/fa";

const SearchResults = () => {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const [suggestions, setSuggestions] = useState([]); // For Google Places suggestions
  const mapRef = useRef(null);
  const [map, setMap] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const searchQuery = params.get("city") || ""; // City search query
  const [showRooms, setShowRooms] = useState(false)
  const [showPrice, setShowPrice] = useState(false)
  const [showSQFT, setShowSQFT] = useState(false)
  const [showDate, setShowDate] = useState(false)
  // Filter states
  const [city, setCity] = useState(searchQuery);
  const [bedrooms, setBedrooms] = useState(0);
  const [bathrooms, setBathrooms] = useState(0);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(100000);
  const [minSQFT, setMinSQFT] = useState(0);
  const [maxSQFT, setMaxSQFT] = useState(100000);
  const [availabilityDate, setAvailabilityDate] = useState(
    params.get("availableFrom") || ""
  );
  const [availabilityDateend, setAvailabilityDateend] = useState(
    params.get("availableTo") || ""
  );
  const [toggleView, setToggleView] = useState(false)
  // properties pagination
  const [currentPropertyItems, setCurrentPropertyItems] = useState(null);
  const [propertyOffset, setPropertyOffset] = useState(0);
  const [propertyPageCount, setPropertyCount] = useState(0);
  const [sortOption, setSortOption] = useState("random");

  // Fetch properties from the backend with filters
  const fetchProperties = (page = 1) => {
    setLoading(true);

    const parts = city.split(",").map((part) => part.trim());
    const parsedCity = parts[0] || ""; // First part is the city
    const parsedState = parts[1] || ""; // Second part is the state (if available)

    let query = `${process.env.REACT_APP_BACKEND_URL}/api/property?city=${parsedCity}&page=${page}&limit=10`;
    if (parsedState) query += `&state=${parsedState}`;
    if (bedrooms) query += `&bedrooms=${bedrooms}`;
    if (bathrooms) query += `&bathrooms=${bathrooms}`;
    if (availabilityDate) query += `&availableFrom=${availabilityDate}`;
    if (availabilityDateend) query += `&availableTo=${availabilityDateend}`;
    query += `&minPrice=${minPrice}&maxPrice=${maxPrice}`;

    fetch(query)
      .then((res) => res.json())
      .then((data) => {
        setProperties(data.totalProperties);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  useEffect(() => {

    if (params.get("bedrooms")) {
      setBedrooms(params.get("bedrooms"))
    }

    if (params.get("bathrooms")) {
      setBathrooms(params.get("bathrooms"))
    }
  }, []);

  // Fetch properties when the component mounts or when the location.search changes
  useEffect(() => {
    fetchProperties(currentPage);
  }, [location.search, currentPage]);

  useEffect(() => {
    if (properties.length > 0) {
      const endPropertyOffset = propertyOffset + 6;
      setCurrentPropertyItems(properties.slice(propertyOffset, endPropertyOffset));
      setPropertyCount(Math.ceil(properties.length / 6));
    }
  }, [propertyOffset, properties])
  useEffect(() => {
    // Initialize the Google Map
    const initMap = () => {
      const mapInstance = new window.google.maps.Map(mapRef.current, {
        center: { lat: 37.7749, lng: -122.4194 }, // Default to San Francisco
        zoom: 12,
      });
      setMap(mapInstance);
    };

    if (!window.google) {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
      script.async = true;
      script.defer = true;
      script.onload = initMap;
      document.body.appendChild(script);
    } else {
      initMap();
    }
  }, []);
  const handleSortChange = (e) => {
    setSortOption(e.target.value);
  };
  
  useEffect(() => {
    if (properties.length > 0) {
      const endPropertyOffset = propertyOffset + 6;
  
      // Apply sorting logic
      let sortedProperties = [...properties];
      if (sortOption === "newest") {
        sortedProperties.sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        ).reverse();
      } else if (sortOption === "oldest") {
        sortedProperties.sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        );
      } else if (sortOption === "lowestPrice") {
        sortedProperties.sort((a, b) => a.price - b.price);
      } else if (sortOption === "highestPrice") {
        sortedProperties.sort((a, b) => b.price - a.price);
      } else if (sortOption === "random") {
        sortedProperties = sortedProperties.sort(() => 0.5 - Math.random());
      }
  
      setCurrentPropertyItems(
        sortedProperties.slice(propertyOffset, endPropertyOffset)
      );
      setPropertyCount(Math.ceil(properties.length / 6));
    }
  }, [propertyOffset, properties, sortOption]);
  
  useEffect(() => {
    if (!map || !properties.length) {
      console.log("Map or properties not ready yet.");
      return;
    }

    console.log("Geocoding addresses for properties:", properties);

    // Function to geocode addresses and add markers
    const geocodeAddresses = async () => {
      const geocoder = new window.google.maps.Geocoder();
      const bounds = new window.google.maps.LatLngBounds(); // Create bounds to adjust the map view

      for (const property of properties) {
        const { address, city, id } = property;
        const fullAddress = `${address}, ${city}`;

        console.log(`Geocoding address: ${fullAddress}`);

        try {
          // Geocode the address
          const response = await geocoder.geocode({ address: fullAddress });

          if (response.results.length > 0) {
            const location = response.results[0].geometry.location;

            console.log(`Geocoded location for ${fullAddress}:`, location);

            // Add a marker to the map
            const marker = new window.google.maps.Marker({
              position: location,
              map,
              title: property.property_name,
            });

            // Extend the bounds to include this marker
            bounds.extend(location);

            // Add click event to navigate to the property page
            marker.addListener("click", () => {
              console.log(`Marker clicked for property ID: ${id}`);
              navigate(`/property/${id}`);
            });
          } else {
            console.warn(`No results found for address: ${fullAddress}`);
          }
        } catch (error) {
          console.error(`Failed to geocode address: ${fullAddress}`, error);
        }
      }

      // Adjust the map to fit all markers
      if (!bounds.isEmpty()) {
        map.fitBounds(bounds);
      } else {
        console.warn("No valid locations to adjust map bounds.");
      }
    };

    geocodeAddresses();
  }, [map, properties, navigate]);
  const fetchSuggestions = async (input) => {
    if (!input) return;

    try {
      const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/places`, {
        params: { input },
      });
      setSuggestions(data.predictions.map((pred) => pred.description));
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setCity(value);

    if (value) {
      fetchSuggestions(value);
    } else {
      setSuggestions([]);
    }
  };

  const formatNumber = (num) => {
    if (Number.isInteger(num)) {
      return num.toString()
    } else {
      const decimalPlaces = (num.toString().split(".")[1] || []).length
      if (decimalPlaces >= 2) {
        return num.toFixed(1); // Return with one decimal place
      } else {
        return num.toString(); // Return as is (could have 1 decimal or more)
      }
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const parts = city.split(",").map((part) => part.trim());
    const parsedCity = parts[0] || ""; // First part is the city
    const parsedState = parts[1] || ""; // Second part is the state

    const queryParams = new URLSearchParams();
    if (parsedCity) queryParams.set("city", `${parsedCity}, ${parsedState}`);
    if (bedrooms) queryParams.set("bedrooms", bedrooms);
    if (bathrooms) queryParams.set("bathrooms", bathrooms);
    if (availabilityDate) queryParams.set("availableFrom", availabilityDate);
    if (availabilityDateend) queryParams.set("availableTo", availabilityDateend);
    queryParams.set("minPrice", minPrice);
    queryParams.set("maxPrice", maxPrice);
    queryParams.set("minSQFT", minSQFT);
    queryParams.set("maxSQFT", maxSQFT);


    navigate(`/search?${queryParams.toString()}`);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleChangeValue = (e) => {
    let { value, name } = e.target;
    if (value === "") {
      value = 0
    } else {
      value = value.replace(/^0+/, ''); // Remove leading zeros
      value = Number(value);
    }

    if (name === "bedrooms") {
      setBedrooms(value)
    } else if (name === "bathrooms") {
      setBathrooms(value)
    } else if (name === "minimum") {
      setMinPrice(value)
    } else if (name === "maximum") {
      setMaxPrice(value)
    } else if (name === "minimums") {
      setMinSQFT(value)
    } else if (name === "maximums") {
      setMaxSQFT(value)
    }


  }

  const handleShowRooms = (e) => {
    e.stopPropagation();
    setShowRooms(!showRooms)
  }

  const handlePropertyPageClick = (event) => {
    const newOffest = (event.selected * 6) % properties.length;
    setPropertyOffset(newOffest)
  }

  return (
    <div className="search-results-container">
      {/* Filter Form */}
      <form onSubmit={handleSubmit} className="flex border px-4 py-2 gap-2 max-[952px]:flex-col">
        <div className="w-2/5 max-[952px]:w-full">
          <input
            type="text"
            value={city}
            onChange={handleInputChange}
            placeholder="Where should we go?"
            className="w-full px-3 py-2 text-base border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-blue-400 focus:border-blue-400"
          />
        </div>
        <div className="flex gap-2 max-[563px]:flex-col">
          <div className="flex gap-x-2 filter-line">
            <OutsideClickHandler
              onOutsideClick={() => {
                setShowRooms(false)
              }}
            >
              <div className="relative">
                <div className="flex items-center border border-gray-300 px-3 py-2 rounded font-semibold text-gray-700 cursor-pointer " onClick={(e) => handleShowRooms(e)}>
                  <div>{bedrooms !== 0 || bathrooms !== 0 ? `${bedrooms} bd, ${bathrooms} ba` : `Beds & Baths`}</div>
                  <svg class="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4" />
                  </svg>
                </div>
                {
                  showRooms === true && <div className="absolute top-[42px] left-0 z-[1] w-[200px] mx-auto bg-gray-100 p-3 rounded-b">
                    <div className="mb-3">
                      <p className="text-sm font-normal mb-1">Bedroom(s)</p>
                      <input
                        type="range"
                        min="1"
                        max="10"
                        value={bedrooms}
                        onChange={(e) => handleChangeValue(e)}
                        name="bedrooms"
                        className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                      />
                      <span className="text-sm text-gray-600 mt-1">{bedrooms} bedroom(s)</span>
                    </div>
                    <div>
                      <p className="text-sm font-normal mb-1">Bathroom(s)</p>
                      <input
                        type="range"
                        min="1"
                        max="10"
                        value={bathrooms}
                        onChange={(e) => handleChangeValue(e)}
                        name="bathrooms"
                        className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                      />
                      <span className="text-sm text-gray-600 mt-1">{bathrooms} bathroom(s)</span>
                    </div>

                  </div>
                }
              </div>
            </OutsideClickHandler>
            <OutsideClickHandler
              onOutsideClick={() => {
                setShowPrice(false)
              }}
            >
              <div className="relative">

                <div className="flex items-center border border-gray-300 px-3 py-2 rounded font-semibold text-gray-700 cursor-pointer " onClick={() => setShowPrice(!showPrice)}>
                  <div>Price</div>
                  <svg class="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4" />
                  </svg>
                </div>

                {
                  showPrice === true && <div className="absolute top-[42px] left-0 z-[1] w-[200px] mx-auto bg-gray-100 p-3 rounded-b">
                    <div className="mb-3">
                      <p className="text-sm font-normal mb-1">Minimum</p>
                      <input
                        type="text"
                        value={minPrice}
                        onChange={(e) => handleChangeValue(e)}
                        placeholder="min price"
                        name="minimum"
                        className="px-3 py-2 w-full text-base border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-blue-400 focus:border-blue-400"
                      />
                    </div>
                    <div>
                      <p className="text-sm font-normal mb-1">Maximum</p>
                      <input
                        type="text"
                        value={maxPrice}
                        onChange={(e) => handleChangeValue(e)}
                        placeholder="max price"
                        name="maximum"
                        className="px-3 py-2 w-full text-base border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-blue-400 focus:border-blue-400"
                      />
                    </div>

                  </div>
                }

              </div>
            </OutsideClickHandler>
            <OutsideClickHandler
              onOutsideClick={() => {
                setShowSQFT(false)
              }}
            >
              <div className="relative">

                <div className="flex items-center border border-gray-300 px-3 py-2 rounded font-semibold text-gray-700 cursor-pointer " onClick={() => setShowSQFT(!showSQFT)}>
                  <div>SQFT</div>
                  <svg class="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4" />
                  </svg>
                </div>

                {
                  showSQFT === true && <div className="absolute top-[42px] left-0 z-[1] w-[200px] mx-auto bg-gray-100 p-3 rounded-b">
                    <div className="mb-3">
                      <p className="text-sm font-normal mb-1">Minimum</p>
                      <input
                        type="text"
                        value={minSQFT}
                        onChange={(e) => handleChangeValue(e)}
                        placeholder="Min SQFT"
                        name="minimums"
                        className="px-3 py-2 w-full text-base border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-blue-400 focus:border-blue-400"
                      />
                    </div>
                    <div>
                      <p className="text-sm font-normal mb-1">Maximum</p>
                      <input
                        type="text"
                        value={maxSQFT}
                        onChange={(e) => handleChangeValue(e)}
                        placeholder="Max SQFT"
                        name="maximums"
                        className="px-3 py-2 w-full text-base border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-blue-400 focus:border-blue-400"
                      />
                    </div>

                  </div>
                }

              </div>
            </OutsideClickHandler>
          </div>
          <div className="flex gap-x-2 filter-line">
            <OutsideClickHandler
              onOutsideClick={() => {
                setShowDate(false)
              }}
            >
              <div className="relative">
                <div className="flex items-center border border-gray-300 px-3 py-2 rounded font-semibold text-gray-700 cursor-pointer " onClick={() => setShowDate(!showDate)}>
                  <div>Availability</div>
                  <svg class="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4" />
                  </svg>
                </div>

                {
                  showDate === true && <div className="absolute top-[42px] left-0 z-[1] w-[200px] mx-auto bg-gray-100 p-3 rounded-b">
                    <div className="mb-3">
                      <p className="text-sm font-normal mb-1">Start Date</p>
                      <input
                        type="date"
                        value={availabilityDate}
                        onChange={(e) => setAvailabilityDate(e.target.value)}
                        className="px-3 py-2 w-full text-base border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-blue-400 focus:border-blue-400"
                      />
                    </div>
                    <div>
                      <p className="text-sm font-normal mb-1">End Date</p>
                      <input
                        type="date"
                        value={availabilityDateend}
                        onChange={(e) => setAvailabilityDateend(e.target.value)}
                        className="px-3 py-2 w-full text-base border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-blue-400 focus:border-blue-400"
                      />
                    </div>
                  </div>
                }
              </div>
            </OutsideClickHandler>
            
            <div type="submit" className="bg-[#02253b] hover:bg-[#ffca2c] text-white text-base px-3 max-[563px]:px-0 rounded font-semibold cursor-pointer flex items-center justify-center text-align-center" onClick={handleSubmit}>
              Apply Filters
            </div>
            <div className="relative">
              <select
                value={sortOption}
                onChange={handleSortChange}
                className="border border-gray-300 px-3 py-2 rounded font-semibold text-gray-700 cursor-pointer"
              >
                <option value="random">Sort: Random</option>
                <option value="newest">Sort: Newest to Oldest</option>
                <option value="oldest">Sort: Oldest to Newest</option>
                <option value="lowestPrice">Sort: Lowest Price</option>
                <option value="highestPrice">Sort: Highest Price</option>
              </select>
            </div>
          </div>
        </div>
      </form>
      


      {/* Main Content */}
      <div className="results-container">
        {/* Property List */}
        <div className=" w-1/2 max-[800px]:w-1/2 flex flex-col justify-between overflow-y-auto max-[768px]:w-full">
          {
            toggleView === true ? <div className="w-full h-full">
              <iframe
                src={
                  properties.length > 0
                    ? `https://www.google.com/maps/embed/v1/search?key=AIzaSyAmtpmge5OnqAfzmyIuSkWLpWrFQkhhz9Y&q=${properties
                      .map((property) => `${property.address}, ${property.city}`)
                      .join("|")}`
                    : `https://www.google.com/maps/embed/v1/place?key=AIzaSyAmtpmge5OnqAfzmyIuSkWLpWrFQkhhz9Y&q=""`
                }
                title="Property Map"
                className="w-full h-full"
              ></iframe>
            </div> : <div>
              {loading ? (
                <p>Loading properties...</p>
              ) : properties.length === 0 ? (
                <p>Sorry, no results found.</p>
              ) : (
                <div className="grid grid-cols-3 gap-3 max-[1024px]:grid-cols-2 max-[800px]:grid-cols-1 px-3 max-[768px]:grid-cols-2 max-[550px]:grid-cols-1">
                  {currentPropertyItems && currentPropertyItems.map((property) => (
                    <div className="property-card" key={property.id}>
                      <Link to={`/property/${property.id}`}>
                        <img
                          src={property.featured_image}
                          alt={property.property_name}
                        />
                        <div className="property-content p-4">
                          <h2 className="text-lg font-semibold text-gray-800">
                            {property.property_name}
                          </h2>
                          <p className="text-sm text-gray-600">
                            {property.address}, {property.city}, {property.state}
                          </p>
                          <p className="text-blue-500 font-medium mt-2">
                            ${property.price.toLocaleString()}
                          </p>
                        </div>
                      </Link>
                    </div>
                  ))}

                </div>
              )}
            </div>
          }

          <div className="flex justify-center max-[768px]:justify-evenly w-full my-6 px-3">
            {
              toggleView === false && <ReactPaginate
                nextLabel=">"
                onPageChange={handlePropertyPageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={propertyPageCount}
                previousLabel="<"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName={`pagination`}
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
            }

            <div
              className="hidden max-[768px]:block bg-blue-300 px-3 py-1 rounded cursor-pointer text-[#0D4599]"
              onClick={() => setToggleView(!toggleView)}
            >
              {/* <div>{toggleView === true ? "List" : "Map"}</div> */}
              {
                toggleView === true 
                ? <div className="flex items-center gap-3">
                    <FaList /> List
                  </div>
                : <div className="flex items-center gap-3">
                    <FaMap /> Map
                  </div>
              }
            </div>
          </div>
        </div>

        {/* Google Maps */}
        <div
          className="border w-1/2 max-[800px]:w-1/2 max-[768px]:hidden"
          ref={mapRef}
          style={{ height: "85vh" }}
        ></div>
      </div>
    </div>
  );
};

export default SearchResults;