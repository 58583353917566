import React, { useState, useEffect } from "react";
import { auth, db, storage } from "./firebaseconfig";
import { onAuthStateChanged } from "firebase/auth";
import { collection, addDoc, getDocs, deleteDoc, doc, serverTimestamp } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router-dom";

const BlogPage = () => {
  const [user, setUser] = useState(null);
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [entryDenied, setEntryDenied] = useState(false);

  // Blog form states
  const [newBlogTitle, setNewBlogTitle] = useState("");
  const [newBlogSnippet, setNewBlogSnippet] = useState("");
  const [newBlogContent, setNewBlogContent] = useState("");
  const [newBlogImage, setNewBlogImage] = useState(null);

  // Admin UID
  const adminUID = "iankQOyiyYfDUChVKbcC3otq8al2";

  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        if (currentUser.uid === adminUID) {
          setUser(currentUser);
          setEntryDenied(false); // Allow admin access
        } else {
          setEntryDenied(true); // Deny admin access
        }
      } else {
        setEntryDenied(true); // Deny admin access for unauthenticated users
      }
      fetchBlogs(); // Fetch blogs for all users
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const fetchBlogs = async () => {
    try {
      const blogsRef = collection(db, "blogs");
      const querySnapshot = await getDocs(blogsRef);
      const blogsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBlogs(blogsData);
    } catch (error) {
      console.error("Error fetching blogs:", error);
    }
  };

  const handleAddBlog = async (e) => {
    e.preventDefault();
    if (!newBlogTitle || !newBlogSnippet || !newBlogContent || !newBlogImage) {
      alert("Please fill out all fields.");
      return;
    }

    try {
      // Upload blog image to Firebase Storage
      const imageRef = ref(storage, `blogs/${newBlogTitle}/featured/${newBlogImage.name}`);
      const uploadTask = uploadBytesResumable(imageRef, newBlogImage);

      const imageUrl = await new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          null,
          (error) => {
            console.error("Error uploading image:", error);
            reject(error);
          },
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(downloadURL);
          }
        );
      });

      // Add blog to Firestore
      const blogsRef = collection(db, "blogs");
      const newBlog = {
        title: newBlogTitle,
        snippet: newBlogSnippet,
        content: newBlogContent,
        image: imageUrl,
        createdAt: serverTimestamp(),
      };
      const docRef = await addDoc(blogsRef, newBlog);
      setBlogs([...blogs, { id: docRef.id, ...newBlog }]);

      // Clear form
      setNewBlogTitle("");
      setNewBlogSnippet("");
      setNewBlogContent("");
      setNewBlogImage(null);

      alert("Blog added successfully!");
    } catch (error) {
      console.error("Error adding blog:", error);
      alert("Failed to add blog. Please try again.");
    }
  };

  const handleDeleteBlog = async (blogId) => {
    try {
      const blogRef = doc(db, "blogs", blogId);
      await deleteDoc(blogRef);
      setBlogs(blogs.filter((blog) => blog.id !== blogId));
      alert("Blog deleted successfully!");
    } catch (error) {
      console.error("Error deleting blog:", error);
    }
  };

  if (loading) {
    return <p className="text-center text-gray-500 mt-10">Loading...</p>;
  }

  return (
    <div className="container mx-auto" style={{ padding: "1rem 4rem" }}>
      <div className="text-center mt-6">
        <h2 className="text-5xl font-extrabold mb-2 text-gray-800">Subliva Blog</h2>
      </div>

      {/* Show Add Blog Form if Admin */}
      {!entryDenied && user?.uid === adminUID && (
        <div className="bg-gray-100 p-6 rounded-lg shadow-lg mb-12">
          <h3 className="text-2xl font-bold mb-4">Add New Blog</h3>
          <form onSubmit={handleAddBlog} className="space-y-4">
            <input
              type="text"
              placeholder="Blog Title"
              value={newBlogTitle}
              onChange={(e) => setNewBlogTitle(e.target.value)}
              className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-400"
            />
            <input
              type="text"
              placeholder="Snippet"
              value={newBlogSnippet}
              onChange={(e) => setNewBlogSnippet(e.target.value)}
              className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-400"
            />
            <textarea
              placeholder="Content"
              value={newBlogContent}
              onChange={(e) => setNewBlogContent(e.target.value)}
              className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-400"
            />
            <input
              type="file"
              accept="image/*"
              onChange={(e) => setNewBlogImage(e.target.files[0])}
              className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-400"
            />
            <button
              type="submit"
              className="w-full p-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition"
            >
              Add Blog
            </button>
          </form>
        </div>
      )}

      {/* Show Blogs */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6" style={{ padding: "3rem" }}>
        {blogs.length === 0 ? (
          <p className="col-span-full text-center text-gray-500">
            No blogs available.
          </p>
        ) : (
          blogs.map((blog) => (
            <div
              key={blog.id}
              className="bg-white rounded-lg shadow-lg overflow-hidden transform transition hover:scale-105"
              style={{ minHeight: "400px" }}
              onClick={() => navigate(`/blogs/${blog.id}`)}
            >
              <img
                src={blog.image}
                alt={blog.title}
                className="w-full h-60 object-cover"
              />
              <div className="p-6" >
                <h3 className="text-xl font-bold mb-2">{blog.title}</h3>
                <p className="text-gray-500 text-sm mb-4">
                  Published on{" "}
                  {blog.createdAt
                  ? new Date(blog.createdAt.seconds * 1000).toLocaleDateString()
                  : "Unknown Date"}
                </p>
                <p className="text-gray-600 mb-4">{blog.snippet}</p>
                {!entryDenied && user?.uid === adminUID && (
                  <button
                    onClick={() => handleDeleteBlog(blog.id)}
                    className="ml-4 text-red-500 hover:underline"
                  >
                    Delete Blog
                  </button>
                )}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default BlogPage;
