import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { auth, db, storage } from './firebaseconfig';
import { doc, setDoc, collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import imageCompression from 'browser-image-compression';
import axios from 'axios';
import Select from "react-select";
import { states } from '../utils/usStateNames';
import Dropzone from 'react-dropzone';
import { FaPlus } from "react-icons/fa6";
import { FaTimes } from "react-icons/fa";


const ListProp = () => {
  const navigate = useNavigate();
  const imageRef = useRef(null);
  const [propertyDetails, setPropertyDetails] = useState({
    property_name: '',
    address: '',
    city: '',
    zip_code: '',
    country: '',
    state: '',
    featured_image: null,
    gallery_image: [],
    description: '',
    price: '',
    area_size: '',
    bedroom: '',
    bathroom: '',
    garage: '',
    youtube_link: '',
    owner: '',
    status: '',
    city_lower: '',
    availabilityStart: new Date(),
    availabilityEnd: new Date(),
  });
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [selectedState, setSelectedState] = useState({})
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [propertyId, setPropertyId] = useState(null);
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [featuredImage, setFeaturedImage] = useState(null)
  const [galleryImage, setGalleryImage] = useState([])

  useEffect(() => {
    const fetch = async () => {
      axios
        .get(
          "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
        )
        .then((res) => {
          setCountries(res.data.countries);
        });
    };
    fetch();
  }, [])
  useEffect(() => {
    const savedDetails = localStorage.getItem('propertyDetails');
    if (savedDetails) {
      setPropertyDetails(JSON.parse(savedDetails));
    }
  }, []);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    const currentYear = new Date().getFullYear();
  
    if (name === 'availabilityStart' && propertyDetails.availabilityEnd && value > propertyDetails.availabilityEnd) {
      setError('Start date cannot be after end date.');
    } else if (name === 'availabilityEnd' && propertyDetails.availabilityStart && value < propertyDetails.availabilityStart) {
      setError('End date cannot be before start date.');
    } else if (name === 'availabilityStart' || name === 'availabilityEnd') {
      const inputYear = new Date(value).getFullYear();
      if (inputYear < currentYear) {
        setError('Date cannot be from a previous year.');
      } else {
        setError(''); 
      }
    } else {
      setError('');
    }
  
    const updatedDetails = {
      ...propertyDetails,
      [name]: (['price', 'area_size', 'bedroom', 'bathroom', 'garage'].includes(name))
        ? parseInt(value) || ''
        : value,
    };
    setPropertyDetails(updatedDetails);
    localStorage.setItem('propertyDetails', JSON.stringify(updatedDetails));
  };
  
  

  const handelGalleryChange = (e) => {
    const { files } = e.target;
    const validTypes = ["image/png", "image/jpg", "image/jpeg"];
    const newFiles = Array.from(files).filter((file) => validTypes.includes(file.type));
    if (newFiles.length != files.length) {
      setError("Only .png and .jpg files are allowed");
      return;
    }
    setGalleryImage((prevImage) => [...prevImage, newFiles[0]])
    setPropertyDetails((prevDetails) => ({
      ...prevDetails,
      gallery_image: [...prevDetails.gallery_image, newFiles[0]],
    }));
    setError(null);
  }
  // Compress image function
  const compressImage = async (file) => {
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 600,
      useWebWorker: true,
    };
    try {
      return await imageCompression(file, options);
    } catch (error) {
      console.error("Error compressing image:", error);
      return file;
    }
  };
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error("User not authenticated. Please log in.");
      }

      // Upload and get URL for Featured Image
      let featuredImageUrl = '';
      if (propertyDetails.featured_image) {
        const compressedFeaturedImage = await compressImage(propertyDetails.featured_image);
        const featuredImageRef = ref(storage, `properties/${propertyDetails.property_name}/featured/${compressedFeaturedImage.name}`);
        const featuredUploadTask = uploadBytesResumable(featuredImageRef, compressedFeaturedImage);

        featuredImageUrl = await new Promise((resolve, reject) => {
          featuredUploadTask.on(
            'state_changed',
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              setProgress(progress);
            },
            (error) => {
              console.error("Error uploading featured image:", error);
              setError("Failed to upload featured image. Please try again.");
              setLoading(false);
              reject(error);
            },
            async () => {
              const downloadURL = await getDownloadURL(featuredUploadTask.snapshot.ref);
              resolve(downloadURL);
            }
          );
        });
      }

      // Upload and get URLs for Gallery Images
      const galleryImageUrls = [];
      if (propertyDetails.gallery_image && propertyDetails.gallery_image.length > 0) {
        for (const file of propertyDetails.gallery_image) {
          const compressedFile = await compressImage(file);
          const galleryImageRef = ref(storage, `properties/${propertyDetails.property_name}/gallery/${compressedFile.name}`);
          const galleryUploadTask = uploadBytesResumable(galleryImageRef, compressedFile);

          await new Promise((resolve, reject) => {
            galleryUploadTask.on(
              'state_changed',
              null,
              (error) => {
                console.error("Error uploading gallery image:", error);
                reject(error);
              },
              async () => {
                const downloadURL = await getDownloadURL(galleryUploadTask.snapshot.ref);
                galleryImageUrls.push(downloadURL);
                resolve();
              }
            );
          });
        }
      }
      const availabilityStart = propertyDetails.availabilityStart
        ? new Date(propertyDetails.availabilityStart)
        : null;
      const availabilityEnd = propertyDetails.availabilityEnd
        ? new Date(propertyDetails.availabilityEnd)
        : null;
      // Exclude File objects from propertyDetails
      const { featured_image, gallery_image, ...propertyData } = propertyDetails;

      // Create Firestore Document with Image URLs

      const pendingCollectionRef = collection(db, 'pending');
      const propertyDocRef = await addDoc(pendingCollectionRef, {
        ...propertyData,
        featured_image: featuredImageUrl,
        gallery_images: galleryImageUrls,
        owner: user.uid,
        ownerEmail: user.email, // Include owner's email
        status: 'pending',
        createdAt: serverTimestamp(),
        city_lower: propertyData.city.toLowerCase(),
        availabilityStart: availabilityStart, // Store as Date
        availabilityEnd: availabilityEnd, // Store as Date
      });
      setPropertyId(propertyDocRef.id);
      setSuccess("Property listed successfully!");
      setShowPopup(true);
      setLoading(false);
    } catch (error) {
      console.error("Error listing property:", error);
      setError(error.message);
      setLoading(false);
    }
    localStorage.removeItem('propertyDetails');
  };

  const handlePopupClose = () => {
    setShowPopup(false);
    navigate('/verify', { state: { propertyId } });
  };

  const onFeaturedDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      if (rejectedFiles[0].errors[0].code === 'file-invalid-type') {
        setError('Only .png and .jpg files are allowed');
        return;
      } else if (rejectedFiles[0].errors[0].code === 'file-too-large') {
        setError('You can upload maximum 50 MB');
        return;
      }
    }

    const file = Object.assign(acceptedFiles[0], {
      preview: URL.createObjectURL(acceptedFiles[0]),
    });
    setFeaturedImage(file)
    setPropertyDetails((prevDetails) => ({
      ...prevDetails,
      featured_image: file,
    }));
    setError(null);
  }

  const removeFeatureImage = (e) => {
    e.stopPropagation()
    setFeaturedImage(null)
  }

  const removeGallaryImage = (e, index) => {
    e.stopPropagation()
    const temp = [...galleryImage];
    temp.splice(index, 1)
    setGalleryImage(temp)
    setPropertyDetails((prevDetails) => ({
      ...prevDetails,
      gallery_image: temp,
    }));
  }

  const clickTerms = () => {
    window.open('/terms', '_blank');
  }

  return (
    <div className='pt-28 pb-12'>
      <div className="">
        <h2 className=" text-[2.5rem] text-[#2a2a2a] text-center mb-6">List Your Property</h2>
        <form onSubmit={handleSubmit} className='flex flex-col gap-y-6 max-w-[768px] m-auto max-[768px]:px-[15px] max-[768px]:w-full max-[450px]:gap-3'>
          <div className='flex gap-8 max-[450px]:flex-col'>
            <div className='w-1/2 max-[450px]:w-full'>
              <label className="block text-sm font-medium text-gray-700 mb-2">Property Name</label>
              <input
                type="text"
                name="property_name"
                value={propertyDetails.property_name}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 border border-gray-300 rounded shadow-sm focus:ring-2 focus:ring-blue-400 focus:outline-none"
              />
            </div>
            <div className='w-1/2 max-[450px]:hidden'></div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Address</label>
            <input
              type="text"
              name="address"
              value={propertyDetails.address}
              onChange={handleChange}
              required
              className="w-full px-4 py-2 border border-gray-300 rounded shadow-sm focus:ring-2 focus:ring-blue-400 focus:outline-none"
            />
          </div>

          <div className="flex gap-8 max-[450px]:flex-col max-[450px]:gap-3">
            <div className='w-1/2 max-[450px]:w-full'>
              <label className="block text-sm font-medium text-gray-700 mb-2">City</label>
              <input
                type="text"
                name="city"
                value={propertyDetails.city}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 border border-gray-300 rounded shadow-sm focus:ring-2 focus:ring-blue-400 focus:outline-none"
              />
            </div>
            <div className='w-1/2 max-[450px]:w-full'>
              <label className="block text-sm font-medium text-gray-700 mb-2">Zip Code</label>
              <input
                type="text"
                name="zip_code"
                value={propertyDetails.zip_code}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 border border-gray-300 rounded shadow-sm focus:ring-2 focus:ring-blue-400 focus:outline-none"
              />
            </div>
          </div>
          
          <div className="flex gap-8 max-[450px]:flex-col max-[450px]:gap-3">
            <div className="w-1/2 max-[450px]:w-full">
              <label className="block text-sm font-medium text-gray-700 mb-2">State</label>
              {selectedCountry.value === "US" ? (
                <Select
                  className="country-select"
                  options={states}
                  value={selectedState}
                  onChange={(selectedOption) => {
                    setSelectedState(selectedOption);
                    setPropertyDetails({ ...propertyDetails, state: selectedOption?.label || "" });
                  }}
                />
              ) : (
                <input
                  type="text"
                  name="state"
                  value={propertyDetails.state}
                  onChange={handleChange}
                  placeholder={selectedCountry.value ? "Not applicable" : "Select a Country"}
                  disabled={!selectedCountry.value} 
                  className={`w-full px-4 py-2 border border-gray-300 rounded shadow-sm focus:ring-2 focus:ring-blue-400 focus:outline-none ${
                    selectedCountry.value ? "" : "opacity-50 cursor-not-allowed"
                  }`}
                />
              )}
            </div>
            <div className="w-1/2 max-[450px]:w-full">
              <label className="block text-sm font-medium text-gray-700 mb-2">Country</label>
              <Select
                className="country-select"
                options={countries}
                value={selectedCountry}
                onChange={(selectedOption) => {
                  setSelectedCountry(selectedOption);
                  setPropertyDetails({ ...propertyDetails, country: selectedOption?.label || "", state: "" });
                  if (selectedOption.value !== "US") {
                    setSelectedState(null);
                  }
                }}
              />
            </div>
          </div>

          <div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Featured Image</label>
              <Dropzone
                onDrop={onFeaturedDrop}
                accept={{
                  'image/jpeg': ['.jpg', '.jpeg'],
                  'image/png': ['.png'],
                }}
                minSize={0}
                maxSize={50 * 1024 * 1024}
              >
                {({ getRootProps, getInputProps }) => {
                  return (
                    <div
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      {
                        featuredImage 
                          ? 
                            <div className='w-[120px] h-[120px] relative'>
                              <img className='h-auto w-auto rounded' style={{ maxWidth: '120px', maxHeight: '120px', objectFit: 'cover' }}  src={featuredImage.preview} alt='featured' />
                              <div className='absolute top-[-12px] right-[-12px] bg-gray-200 p-1 cursor-pointer rounded-full' onClick={(e) => removeFeatureImage(e)}>
                                <FaTimes className='text-gray-500' />
                              </div>
                            </div>
                         : <div className='border-2 border-gray-300 border-dashed rounded w-[120px] h-[120px] flex items-center justify-center'>
                          <FaPlus size={48} className='text-gray-500' />
                        </div>
                      }

                    </div>
                  );
                }}
              </Dropzone>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Gallery Images</label>
            <div className='flex flex-wrap gap-6'>
              {
                galleryImage.length > 0 && galleryImage.map((img, index) => {
                  return (
                    <div className='w-[120px] h-[120px] relative' index={index}>
                      <img className='h-auto w-auto rounded' style={{ maxWidth: '120px', maxHeight: '120px', objectFit: 'cover' }} src={URL.createObjectURL(img)} alt='featured' />
                      <div className='absolute top-[-12px] right-[-12px] bg-gray-200 p-1 cursor-pointer rounded-full' onClick={(e) => removeGallaryImage(e, index)}>
                        <FaTimes className='text-gray-500' />
                      </div>
                    </div>
                  )
                })
              }
              <div>
                <div className='border-2 border-gray-300 border-dashed rounded w-[120px] h-[120px] flex items-center justify-center cursor-pointer' onClick={() => imageRef.current.click()}>
                  <FaPlus size={48} className='text-gray-500' />
                </div>
                <input
                  type="file"
                  accept="image/*"
                  ref={imageRef}
                  style={{ display: "none" }}
                  onChange={handelGalleryChange}
                />
              </div>
            </div>
          </div>

          <div className='flex gap-8 max-[450px]:flex-col max-[450px]:gap-3'>
            <div className='w-1/2 max-[450px]:w-full'>
              <label className="block text-sm font-medium text-gray-700 mb-2">Bedroom(s)</label>
              <input
                type="number"
                name="bedroom"
                value={propertyDetails.bedroom}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 border border-gray-300 rounded shadow-sm focus:ring-2 focus:ring-blue-400 focus:outline-none"
              />
            </div>
            <div className='w-1/2 max-[450px]:w-full'>
              <label className="block text-sm font-medium text-gray-700 mb-2">Bathroom(s)</label>
              <input
                type="number"
                name="bathroom"
                value={propertyDetails.bathroom}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 border border-gray-300 rounded shadow-sm focus:ring-2 focus:ring-blue-400 focus:outline-none"
              />
            </div>
          </div>

          <div className='flex gap-8 max-[450px]:flex-col max-[450px]:gap-3'>
            <div className='w-1/2 max-[450px]:w-full'>
              <label className="block text-sm font-medium text-gray-700 mb-2">Price</label>
              <input
                type="number"
                name="price"
                value={propertyDetails.price}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 border border-gray-300 rounded shadow-sm focus:ring-2 focus:ring-blue-400 focus:outline-none"
              />
            </div>
            <div className='w-1/2 max-[450px]:w-full'>
              <label className="block text-sm font-medium text-gray-700 mb-2">Sqft:</label>
              <input
                type="number"
                name="area_size"
                value={propertyDetails.area_size}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 border border-gray-300 rounded shadow-sm focus:ring-2 focus:ring-blue-400 focus:outline-none"
              />
            </div>
          </div>

          <div className='flex gap-8 max-[450px]:flex-col max-[450px]:gap-3'>
            <div className='w-1/2 max-[450px]:w-full'>
              {/* Availability Start Date.*/}
              <label className="block text-sm font-medium text-gray-700 mb-2">Availability Start Date</label>
              <input
                type="date"
                name="availabilityStart"
                value={propertyDetails.availabilityStart}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 border border-gray-300 rounded shadow-sm focus:ring-2 focus:ring-blue-400 focus:outline-none"
              />
            </div>
            <div className='w-1/2 max-[450px]:w-full'>
              {/* Availability End Date */}
              <label className="block text-sm font-medium text-gray-700 mb-2">Availability End Date</label>
              <input
                type="date"
                name="availabilityEnd"
                value={propertyDetails.availabilityEnd}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 border border-gray-300 rounded shadow-sm focus:ring-2 focus:ring-blue-400 focus:outline-none"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Description</label>
            <textarea
              name="description"
              value={propertyDetails.description}
              onChange={handleChange}
              required
              rows={3}
              className="w-full px-4 py-2 border border-gray-300 rounded shadow-sm focus:ring-2 focus:ring-blue-400 focus:outline-none resize-none"
            />
          </div>

          <div className='flex items-center gap-3 '>
            <input
              type="checkbox"
              name="termsAccepted"
              checked={termsAccepted}
              onChange={(e) => setTermsAccepted(!termsAccepted)}
              required
              className='w-[16px] h-[16px]'
            />
            <Link className='text-blue-500 hover:underline cursor-pointer' to="/terms">I accept the Terms of Conditions</Link>
          </div>

          {loading && (
            <div className="progress-bar bg-blue-500 rounded-full h-2 mt-3" style={{ width: `${progress}%` }}></div>
          )}
          <button
            type="submit"
            disabled={loading}
            className={`px-4 py-3 text-white font-medium shadow-none w-[200px] m-auto rounded focus:ring-2 focus:ring-blue-400 ${loading ? "bg-gray-400" : "bg-blue-500 hover:bg-blue-600"
              }`}
          >
            {loading ? "Listing..." : "List Property"}
          </button>
        </form>

        {error && <p className="text-sm text-red-500 text-center mt-3">{error}</p>}
        {success && <p className="text-sm text-green-500 text-center mt-3">{success}</p>}

        {showPopup && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white rounded-lg p-6 shadow-lg text-center">
              <p className="text-gray-800 mb-4">Oh wait! We need to verify your info.</p>
              <button
                onClick={handlePopupClose}
                className="px-4 py-2 bg-blue-500 text-white rounded-lg shadow-md hover:bg-blue-600 focus:ring-2 focus:ring-blue-400"
              >
                Let's Go!
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );

};

export default ListProp;
