import React from 'react';
import './help.css';

const Help = () => {
  const handleEmailPress = () => {
    window.location.href = "mailto:support@subliva.com";
  };

  const handleWebsitePress = () => {
    window.open("https://example.com", "_blank");
  };

  return (
    <div className="container">
      <h1 className="header">Need Help?</h1>
      <p className="subHeader">Contact us and we'll assist you!</p>
      <div className="contactSection">
        <div className="contactItem" onClick={handleEmailPress}>
          <span className="label">Email:</span>
          <span className="value">support@subliva.com</span>
        </div>
        <div className="guideSection">
          <h2 className="guideHeader">How to Use Our Website</h2>
          <ol className="guideList">
            <li className="guideStep">
              <strong>Sign Up or Sign In:</strong> Create an account or log in using your credentials.
            </li>
            <li className="guideStep">
              <strong>Search for a Property:</strong> Use the search bar to find properties that suit your needs.
            </li>
            <li className="guideStep">
              <strong>Contact the Owner:</strong> Reach out to the property owner using the provided contact details.
            </li>
            <li className="guideStep">
              <strong>Negotiate:</strong> Discuss terms with the owner, including rent and lease duration.
            </li>
            <li className="guideStep">
              <strong>Agree on Terms:</strong> Come to a mutual agreement on the lease details.
            </li>
            <li className="guideStep">
              <strong>Wait for the Owner to Send a Contract:</strong> The owner will send a contract for your review.
            </li>
            <li className="guideStep">
              <strong>Sign the Contract:</strong> Review and sign the contract to finalize the agreement.
            </li>
            <li className="guideStep">
              <strong>Make the Payment:</strong> Complete the payment process as agreed with the owner.
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default Help;