import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "./firebaseconfig";
import DOMPurify from "dompurify";

const BlogDetails = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const blogRef = doc(db, "blogs", id);
        const blogDoc = await getDoc(blogRef);
        if (blogDoc.exists()) {
          setBlog(blogDoc.data());
        } else {
          console.error("Blog not found!");
        }
      } catch (error) {
        console.error("Error fetching blog details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlog();
  }, [id]);

  const formatContent = (content) => {
    return content
      .split("\n")
      .map((paragraph) => `<p>${paragraph}</p><br>`)
      .join("");
  };  

  if (loading) {
    return <p className="text-center text-gray-500 mt-10">Loading...</p>;
  }

  if (!blog) {
    return <p className="text-center text-red-500 mt-10">Blog not found.</p>;
  }

  return (
    <div className="container mx-auto p-6">
      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <img
          src={blog.image}
          alt={blog.title}
          className="w-full h-96 object-cover"
        />
        <div className="p-6">
          <h1 className="text-4xl font-bold mb-4 text-gray-900">{blog.title}</h1>
          <p className="text-gray-600 mb-4">{blog.snippet}</p>
          <div
            className="text-lg text-gray-700 leading-relaxed text-left"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(formatContent(blog.content)),
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
