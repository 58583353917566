import React from "react";
import "./about.css";
import Faq from "react-faq-component";

const faqData = {
  rows: [
    {
      title: "What is Subliva?",
      content:
        "Subliva is a user-friendly platform designed to streamline the subleasing process for both sublessors and sublessees.",
    },
    {
      title: "How do I use Subliva as a sublessor?",
      content: `
      To use Subliva as a sublessor, start by creating a listing. Upload high-quality photos of your property, provide detailed information, and set your subleasing terms. Next, verify your listing by submitting proof of address, such as a lease agreement or utility bill, to build trust with potential sublessees. Once your listing is live, you can respond to inquiries, negotiate terms, and finalize agreements effortlessly through the platform.
      `,
    },
    {
      title: "How do I use Subliva as a sublessee?",
      content: `
      As a sublessee, you can begin by browsing listings using Subliva's advanced search filters to find subleases that match your preferences. To connect with sublessors, create an account that allows you to directly message them, ask questions, and schedule property visits. Once you've found the right sublease, you can use Subliva's automated contract feature to secure your agreement seamlessly and stress-free.
      `,
    },
    {
      title: "Are listings verified?",
      content:
        "Yes, all listings undergo a verification process through proof of address submissions, ensuring trust and transparency.",
    },
    {
      title: "What makes Subliva's process transparent?",
      content:
        "Subliva maintains transparency by providing clear terms for every transaction and requiring listing verification to build a trustworthy and reliable platform.",
    },
    {
      title: "What automated features does Subliva offer?",
      content:
        "Subliva enhances convenience with automated features such as secure digital contract signing, payment processing, and a user-friendly messaging system, all designed to simplify subleasing.",
    },
    {
      title: "Are there any hidden fees on Subliva?",
      content: "No, Subliva is completely transparent. Listing your property is always free, with no hidden fees.",
    },
    {
      title: "Is it free to list a property?",
      content: "Yes, listing your property on Subliva is completely free and straightforward.",
    },
  ],
};


const faqStyles = {
  rowTitleColor: "#555",
  rowContentColor: "#666",
  arrowColor: "#333",
  rowContentPaddingBottom: "15px",
};


const faqConfig = {
  animate: true,
  expandIcon: "+",
  collapseIcon: "-",
  tabFocus: true,
};

const AboutUs = () => {
  return (
    <div className="container">
      <h1 className="header">About</h1>

      <div className="section">
        <h2 className="subHeader">What is Subliva?</h2>
        <p className="text">
          Welcome to Subliva, the ultimate platform for easy, transparent, and
          stress-free subleasing. Whether you’re looking to sublease your
          apartment or searching for the perfect temporary home, Subliva
          simplifies the process. Our mission is to empower renters and sublessors with tools that
          foster transparency, flexibility, and security—removing the barriers
          that make subleasing difficult.
        </p>
      </div>

      <div className="section">
        <h2 className="subHeader">Watch Our Video</h2>
        <div className="videoContainer">
          <div className="videoPlaceholder">
            <span className="videoPlaceholderText">[Video Placeholder]</span>
          </div>
        </div>
        <p className="text">
          🎥 Click the video above to learn more about how Subliva works, why
          we created it, and how it can simplify your subleasing experience.
        </p>
      </div>

      <div className="faq-container">
        <h2>Frequently Asked Questions</h2>
        <Faq data={faqData} styles={faqStyles} config={faqConfig} />
      </div>
    </div>
  );
};

export default AboutUs;
