import React, { useState, useEffect } from 'react';
import { auth, db } from './firebaseconfig';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { updateEmail, updatePassword, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';
import './Settings.css';
import { IoMdEye, IoMdEyeOff } from "react-icons/io";

const Settings = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showReauthModal, setShowReauthModal] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [passwordShow, setPasswordShow] = useState(false)

  // Form states
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    newPassword: '',
    confirmPassword: '',
    notifications: {
      email: true,
      messages: true,
      marketing: false
    }
  });

  useEffect(() => {
    const fetchUserData = async () => {
      if (auth.currentUser) {
        try {
          const userDoc = await getDoc(doc(db, 'users', auth.currentUser.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setFormData(prevState => ({
              ...prevState,
              name: userData.name || '',
              email: auth.currentUser.email,
              phone: userData.phone || '',
              notifications: userData.notifications || {
                email: true,
                messages: true,
                marketing: false
              }
            }));
            setUser(userData);
          }
        } catch (err) {
          setError('Failed to load user data');
          console.error(err);
        }
      }
      setLoading(false);
    };

    fetchUserData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === 'checkbox') {
      setFormData(prevState => ({
        ...prevState,
        notifications: {
          ...prevState.notifications,
          [name]: checked
        }
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const handleReauthentication = async (e) => {
    e.preventDefault();
    try {
      const credential = EmailAuthProvider.credential(
        auth.currentUser.email,
        currentPassword
      );
      await reauthenticateWithCredential(auth.currentUser, credential);
      setShowReauthModal(false);
      setCurrentPassword('');
      // Continue with the pending update
      if (formData.email !== user.email) {
        await updateEmail(auth.currentUser, formData.email);
      }
      if (formData.newPassword) {
        await updatePassword(auth.currentUser, formData.newPassword);
      }
      handleSaveChanges(e);
    } catch (err) {
      setError('Invalid password');
    }
  };

  const handleSaveChanges = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    try {
      // Validate passwords if changing
      if (formData.newPassword) {
        if (formData.newPassword !== formData.confirmPassword) {
          setError('Passwords do not match');
          return;
        }
        if (formData.newPassword.length < 6) {
          setError('Password must be at least 6 characters');
          return;
        }
      }

      // Check if email or password is being changed
      if (formData.email !== auth.currentUser.email || formData.newPassword) {
        setShowReauthModal(true);
        return;
      }

      // Update user document in Firestore
      const userRef = doc(db, 'users', auth.currentUser.uid);
      await updateDoc(userRef, {
        name: formData.name,
        phone: formData.phone,
        notifications: formData.notifications
      });

      setSuccess('Settings updated successfully');
    } catch (err) {
      setError(err.message);
      console.error(err);
    }
  };

  if (loading) {
    return <div className="settings-loading">Loading settings...</div>;
  }

  return (
    <div className="pt-28 pb-12">
      <h2 className=' text-[2.5rem] text-[#2a2a2a] text-center mb-6'>Account Settings</h2>

      {error && <div className="error-message">{error}</div>}
      {success && <div className="success-message">{success}</div>}

      <form onSubmit={handleSaveChanges} className="max-w-[768px] m-auto flex flex-col gap-4 max-[768px]:px-4">
        <div className="settings-section">
          <h2>Personal Information</h2>

          <div className='flex gap-4 mb-4 max-[600px]:flex-col max-[600px]:mb-0'>
            <div className="text-[#555] flex flex-col gap-y-1 w-1/2 max-[600px]:w-full">
              <label className='' htmlFor="name">Full Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Your full name"
                className='outline-none px-4 py-2  rounded border border-[#ddd] text-[#555] focus:border-[#007bff] focus:ring-2 focus:ring-blue-500 focus:ring-opacity-25'
              />
            </div>
            <div className='w-1/2'></div>
          </div>

          <div className='flex gap-4 max-[600px]:flex-col'>
            <div className="text-[#555] flex flex-col gap-y-1 w-1/2 max-[600px]:w-full">
              <label htmlFor="email">Email Address</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Your email"
                className='outline-none px-4 py-2 rounded border border-[#ddd] text-[#555] focus:border-[#007bff] focus:ring-2 focus:ring-blue-500 focus:ring-opacity-25'
              />
            </div>
            <div className="text-[#555] flex flex-col gap-y-1 w-1/2 max-[600px]:w-full">
              <label htmlFor="phone">Phone Number</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                placeholder="Your phone number"
                className='outline-none px-4 py-2  rounded border border-[#ddd] text-[#555] focus:border-[#007bff] focus:ring-2 focus:ring-blue-500 focus:ring-opacity-25'
              />
            </div>
          </div>
        </div>

        <div className="settings-section">
          <h2>Password</h2>
          <div className='flex gap-4 max-[600px]:flex-col'>
            <div className="text-[#555] flex flex-col gap-y-1 w-1/2 max-[600px]:w-full">
              <label htmlFor="newPassword">Enter New Password</label>
              <div className='flex items-center justify-between px-4 py-2  rounded border border-[#ddd] focus:border-[#007bff] focus:ring-2 focus:ring-blue-500 focus:ring-opacity-25'>
                <input
                  type={passwordShow === true ? "text" : "password"}
                  id="newPassword"
                  name="newPassword"
                  value={formData.newPassword}
                  onChange={handleInputChange}
                  placeholder="Enter new password"
                  className='focus:ring-0 border-none text-[#555] px-0 w-full outline-none'
                />
                <div>
                  {
                    passwordShow === true 
                    ? <div className=' cursor-pointer' onClick={() => setPasswordShow(!passwordShow)}><IoMdEye /></div> 
                    : <div className=' cursor-pointer' onClick={() => setPasswordShow(!passwordShow)}><IoMdEyeOff /></div>
                  }
                </div>
              </div>
            </div>
            <div className="text-[#555] flex flex-col gap-y-1 w-1/2 max-[600px]:w-full">
              <label htmlFor="confirmPassword">Confirm New Password</label>
              <div className='flex items-center justify-between px-4 py-2  rounded border border-[#ddd] focus:border-[#007bff] focus:ring-2 focus:ring-blue-500 focus:ring-opacity-25'>
                <input
                  type={passwordShow === true ? "text" : "password"}
                  id="confirmPassword"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                  placeholder="Confirm new password"
                  className='focus:ring-0 border-none text-[#555] px-0 w-full outline-none'
                />
                <div>
                  {
                    passwordShow === true 
                    ? <div className=' cursor-pointer' onClick={() => setPasswordShow(!passwordShow)}><IoMdEye /></div> 
                    : <div className=' cursor-pointer' onClick={() => setPasswordShow(!passwordShow)}><IoMdEyeOff /></div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="settings-section">
          <h2>Notifications</h2>

          <div className="flex items-center gap-3">
            <input
              type="checkbox"
              name="email"
              checked={formData.notifications.email}
              onChange={handleInputChange}
              className='rounded focus:ring-0 focus:ring-offset-0'
            />
            <p className='text-[#555] text-lg'>Email Notifications</p>
          </div>

          <div className="flex items-center gap-3">
            <input
              type="checkbox"
              name="messages"
              checked={formData.notifications.messages}
              onChange={handleInputChange}
              className='rounded focus:ring-0 focus:ring-offset-0'
            />
            <p className='text-[#555] text-lg'>Message Notifications</p>
          </div>

          <div className="flex items-center gap-3">
            <input
              type="checkbox"
              name="marketing"
              checked={formData.notifications.marketing}
              onChange={handleInputChange}
              className='rounded focus:ring-0 focus:ring-offset-0'
            />
            <p className='text-[#555] text-lg font-base'>Marketing Emails</p>
          </div>
        </div>

        <div className="settings-actions">
          <button type="submit" className="save-button">
            Save Changes
          </button>
        </div>
      </form>

      {showReauthModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Confirm Your Password</h2>
            <p>Please enter your current password to continue</p>
            <form onSubmit={handleReauthentication}>
              <input
                type="password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                placeholder="Current password"
                required
              />
              <div className="modal-actions">
                <button type="button" onClick={() => setShowReauthModal(false)}>
                  Cancel
                </button>
                <button type="submit">
                  Confirm
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Settings; 