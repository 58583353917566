import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth, db } from './firebaseconfig';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import './saved.css';
const Saved = () => {
  const [savedProperties, setSavedProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
      } else {
        // Redirect to login page if not authenticated
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    const fetchSavedProperties = async () => {
      if (!currentUser) {
        setLoading(false);
        return;
      }

      try {
        // Fetch the current user's document
        const userDocRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userDocRef);

        if (!userDoc.exists()) {
          setSavedProperties([]);
          setLoading(false);
          return;
        }

        const savedPropertyIds = userDoc.data().savedProperties || [];

        if (savedPropertyIds.length === 0) {
          setSavedProperties([]);
          setLoading(false);
          return;
        }

        // Query the properties collection to fetch the saved properties
        const propertiesRef = collection(db, 'properties');
        const propertiesQuery = query(
          propertiesRef,
          where('__name__', 'in', savedPropertyIds) // Firestore allows querying by document IDs using `__name__`
        );

        const propertiesSnapshot = await getDocs(propertiesQuery);

        const propertiesData = propertiesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setSavedProperties(propertiesData);
        console.log(propertiesData);
      } catch (err) {
        console.error('Error fetching saved properties:', err);
        setError('Failed to load saved properties. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchSavedProperties();
  }, [currentUser]);

  if (loading) {
    return <div className="loading">Loading your saved properties...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="max-w-[1024px] min-h-[90vh] mx-auto pt-28 pb-12 max-[1044px]:px-4">
      <h1 className="text-[2.5rem] text-[#2a2a2a] text-center mb-6">Saved Properties</h1>

      {/* No Saved Properties */}
      {savedProperties.length === 0 ? (
        <div className="no-saved-properties text-center py-12 bg-white rounded-lg shadow-md">
          <p className="text-lg text-gray-600 mb-4">You haven’t saved any properties yet.</p>
          <Link
            to="/"
            className="bg-blue-600 text-white py-3 px-6 rounded-lg shadow hover:bg-blue-700 focus:ring-2 focus:ring-blue-400"
          >
            Browse Properties
          </Link>
        </div>
      ) : (
        /* Saved Properties */
        <div className="grid grid-cols-2 max-[500px]:grid-cols-1 gap-4">
          {savedProperties.map((property) => (
            // <div
            //   key={property.id}
            //   className="property-card bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow overflow-hidden"
            // >
            //   <div className="flex flex-col">
            //     <div>
            //       <img
            //       src={property.featured_image}
            //       alt={property.property_name}
            //       className="w-full h-64 object-cover"
            //       />
            //     </div>

            //     {/* Property Info */}
            //     <div className="py-3 px-4 w-full">
            //       <div className="text-2xl text-gray-800 truncate">
            //         {property.property_name}
            //       </div>
            //       <p className="text-sm text-gray-600 mt-2 truncate">
            //         {property.address}, {property.city}
            //       </p>
            //       <div className="property-details flex justify-between text-sm text-gray-700 mt-4">
            //         <span>{property.bedroom || 0} Beds</span>
            //         <span>{property.bathroom || 0} Baths</span>
            //         <span>{property.area_size || 0} sqft</span>
            //       </div>
            //       <p className="text-xl font-semibold text-blue-600 mt-4">
            //         ${property.price}
            //       </p>

            //       {/* View Details Link */}
            //       <div className="view-details mt-4">
            //         <Link
            //           to={`/property/${property.id}`}
            //           className="text-sm text-blue-600 hover:underline"
            //         >
            //           View Details
            //         </Link>
            //       </div>
            //     </div>
            //   </div>
            // </div>
            <div
              key={property.id}
              className="listing-card bg-white flex rounded-lg shadow-lg hover:shadow-xl transition-shadow overflow-hidden"
            >
              <div className="w-1/3 max-[768px]:w-full h-[200px] max-[768px]:h-[150px]">
                {property.featured_image ? (
                  <img
                    src={property.featured_image}
                    alt={property.property_name}
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <div className="">
                    No Image Available
                  </div>
                )}
              </div>

              <div className="py-2 px-4 w-2/3 max-[768px]:w-full">
                <h2 className="text-2xl font-bold text-gray-800 truncate">
                  {property.property_name}
                </h2>
                <p className="text-sm text-gray-600 mt-2 truncate">
                  {property.address}, {property.city}
                </p>
                <div className="flex gap-2 text-sm text-gray-700 mt-2">
                  <span>{property.bedroom || 0} Beds</span>
                  <div className='w-[1px] border border-gray-700'></div>
                  <span>{property.bathroom || 0} Baths</span>
                  <div className='w-[1px] border border-gray-700'></div>
                  <span>{property.area_size || 0} sqft</span>
                </div>
                <p className="text-xl font-semibold text-blue-600 mt-2">
                  ${property.price}
                </p>
                <div className="tex-[#555] mt-2 flex items-center">
                  <p>
                    Status:{' '}
                    <span
                      className={`${
                        property.status === 'featured'
                          ? 'text-yellow-500'
                          : property.status === 'Active'
                          ? 'text-green-500'
                          : 'text-red-500'
                      }`}
                    >
                      {property.status}
                    </span>
                  </p>
                  {property.status === 'featured' && (
                    <span className="ml-2 text-yellow-500 text-lg">⭐</span>
                  )}
                </div>

                <div className="listing-actions flex justify-between items-center mt-2">
                  <Link
                    to={`/property/${property.id}`}
                    className="text-sm text-blue-600 hover:underline"
                  >
                    View Details
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Saved;
